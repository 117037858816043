import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap";
import Swal from 'sweetalert2';
import Tabs from 'react-bootstrap/Tabs';
import { UserContext } from '../context/UserProvider';
import Tab from 'react-bootstrap/Tab';
import Buscar from '../componentes/Buscar';
import TipopdfNomina from './TipopdfNomina';

const modeloLiquidacionNomina = {
    id: 0,
    salariominimo: 0,
    auxiliotransporte: 0,
    limiteauxiliotransporte: 0,
    diasminimopagovacaciones: 0,
    porcentajeanualinteresescesantias: 0,
    porcentajeretefuente: 0,
    porcentajearetenerporexcendete: 0,
    porcentajemaximoexento: 0,
    valormaximoexcento: 0,
    porcentajeeducaacionysalud: 0,
    porcentajelicenciaeg: 0,
    valorvivienda: 0,
    valorretefuenteuvt: 0,
    horassemanales: 0,
    norealizarcontabilizacionencierres: "",
    liquidacionporcentrodecostosparcial: "",
    tipoderedondeo: "",
    cuentacausacion: "",
    transfiereenlinea: "",
    saludempleador: 0,
    saludempleado: 0,
    saludlimiteinferior: 0,
    saludlimitesuperior: 0,
    pensionempleador: 0,
    pensionempleado: 0,
    pensionlimiteinferior: 0,
    pensionlimitesuperior: 0,
    fondosolidaridadempleado: 0,
    fondosolidaridadlimiteinferior: 0,
    fondosolidaridadlimitesuperior: 0,
    parafiscalescajacompensacion: 0,
    parafiscalesicbf: 0,
    parafiscalessena: 0,
    parafiscalesescuelasindustriales: 0,
    exoneradodeaportesparafiscales: "",
    consecutivonomina: "",
    liquidacionfechacorte: "",
    liquidaciontipoliquidacion: "quincenal",
    liquidacionano: "",
    liquidacionmes: "",
    liquidacionperiodo: "",
    liquidacionaprendizajeconcepto: "",
    liquidacionaprendizajefestivo: "",
    liquidacionaprendizajedominicalesfestivos: "",
};

const modeloSucursal = {
    nit: "",
    nombre: "",
    descripcion: "",
    telefono: "",
    direccion: "",
    email: "",
    celular: "",
    activo: "si",
    fechaultimopago: "",
    maximafechadeuso: "",
    electronica: "no",
    clavecertificado: "",
    tipopersona: "",
    responsabilidadfiscal: "",
    regimenfiscal: "",
    matriculamercantil: ""
};

const LiquidacionNomina = (props) => {
    const { user } = useContext(UserContext);
    const [LiquidacionNomina, setLiquidacionNomina] = useState(modeloLiquidacionNomina);
    const [Liquidaciones, setLiquidaciones] = useState([]);
    const [editar, setEditar] = useState(false);
    const [verModal, setVerModal] = useState(false);
    const [key, setKey] = useState('Datos');
    const [pendiente, setPendiente] = useState(true);
    const [seleccion, setseleccion] = useState("todos");
    const [centrodecosto, setcentrodecosto] = useState("-");
    const [empleado, setempleado] = useState("-");
    const [titulobuscar, settitulobuscar] = useState("");
    const [namebuscado, setnamebuscado] = useState("");
    const [encabezadobuscar, setencabezadobuscar] = useState([]);
    const [datosbuscados, setdatosbuscados] = useState([]);
    const [verModaltipodocumentos, setverModaltipodocumentos] = useState(false);
    const [Empleados, setEmpleados] = useState([]);
    const [CentrodeCosto, setCentrodeCosto] = useState([]);
    const [empleadosliquidados, setempleadosliquidados] = useState([]);
    const [abrirvolantesdepago, setabrirvolantesdepago] = useState(false);
    const [plantillapdf, setplantillapdf] = useState([]);
    const [plantillaspdf, setplantillaspdf] = useState([]);
    const [sucursal, setsucursal] = useState(modeloSucursal)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLiquidacionNomina({ ...LiquidacionNomina, [name]: value });
    };
    const obtenerCentrosdeCosto = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/Ctcentrocostoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setCentrodeCosto(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const obtenerLiquidaciones = async (coincidencia = "") => {
        try {
            const dt = JSON.parse(user);
            const response = await fetch(`${props.servidor}/LiquidacionNominaapi/${coincidencia}?llave=${dt.usuario.llaveempresa}`);

            if (response.ok) {
                const data = await response.json();
                setLiquidaciones(data);
                setPendiente(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const dt = JSON.parse(user);
        obtenerLiquidaciones();
        obtenerEmpleados("")
        obtenerCentrosdeCosto("")
        obtenerPlantillaspdf("")
    }, [user]);

 
    const obtenerEmpleados = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/EmpleadosApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setEmpleados(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const cerrartodo = () => {
        settitulobuscar("")
        setencabezadobuscar([])
        setverModaltipodocumentos(false)
        setdatosbuscados([])
        setnamebuscado("")
    };
    const columns = [
        {
            name: 'Fecha de Corte',
            selector: row => row.liquidacionfechacorte,
            sortable: true,
        },
        {
            name: 'Tipo de Liquidación',
            selector: row => row.liquidaciontipoliquidacion,
            sortable: true,
        },
        {
            name: 'Año',
            selector: row => row.liquidacionano,
            sortable: true,
        },
        {
            name: 'Mes',
            selector: row => row.liquidacionmes,
            sortable: true,
        },
        {
            name: 'Periodo',
            selector: row => row.liquidacionperiodo,
            sortable: true,
        },
        {
            name: 'Concepto Aprendizaje',
            selector: row => row.liquidacionaprendizajeconcepto,
            sortable: true,
        },
        {
            name: 'Festivo Aprendizaje',
            selector: row => row.liquidacionaprendizajefestivo,
            sortable: true,
        },
        {
            name: 'Dominicales y Festivos Aprendizaje',
            selector: row => row.liquidacionaprendizajedominicalesfestivos,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <Button color="primary" size="sm" onClick={() => abrirEditarModal(row)}>
                    <i className="fas fa-pen-alt"></i>
                </Button>
            ),
        },
    ];

    const abrirEditarModal = (data) => {
        setLiquidacionNomina(data);
        setVerModal(true);
        setEditar(true);
    };

    const cerrarModal = () => {
        setLiquidacionNomina(modeloLiquidacionNomina);
        setVerModal(false);
        setEditar(false);
    };
    const obtenerPlantillaspdf = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)

            let response = await fetch(props.servidor + '/PlantillapdfApi?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setplantillaspdf(data)
                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const guardarCambios = async () => {
        try {
            const dt = JSON.parse(user);
            const method = editar ? 'PUT' : 'POST';
            const response = await fetch(`${props.servidor}/LiquidacionNominaapi?llave=${dt.usuario.llaveempresa}&seleccion=${seleccion}&centrodecosto=${centrodecosto}&empleado=${empleado}`, {
                method,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify(LiquidacionNomina),
            });

            if (response.ok) {
                let data = await response.json()
                setempleadosliquidados(data.empleados)

                var plantilla = plantillaspdf.find(p => p.codigo == "nomina")
                if (plantilla) {
                    setplantillapdf(plantilla)
                    setabrirvolantesdepago(true)
                }
                cerrarModal();
            } else {
                const errorText = await response.json()
                const mensajes = errorText.mensaje;
                Swal.fire('Error al Guardar!', mensajes, 'error');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: 'white' }}>Liquidaciones de Nómina</CardHeader>
                <CardBody>
                    <div className="mb-3">
                        <InputGroup>
                            <Input id="buscar" placeholder="Buscar" />
                            <Button color="primary" onClick={() => obtenerLiquidaciones(document.getElementById('buscar').value)}>Buscar</Button>
                            <Button color="success" onClick={() => setVerModal(true)}>Nuevo</Button>
                        </InputGroup>
                    </div>
                    <DataTable
                        columns={columns}
                        data={Liquidaciones}
                        progressPending={pendiente}
                        pagination
                        customStyles={{
                            headCells: {
                                style: { fontSize: '13px', fontWeight: 800 },
                            },
                            headRow: {
                                style: { backgroundColor: '#eee' },
                            },
                        }}
                    />
                </CardBody>
            </Card>

            <Modal size="lg" style={{ maxWidth: '1000px', width: '100%' }} isOpen={verModal} toggle={cerrarModal}>
                <ModalHeader toggle={cerrarModal}>Liquidación de Nómina</ModalHeader>
                <ModalBody>
                    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Fecha de Corte</Label>
                                        <Input
                                            type="date"
                                            name="liquidacionfechacorte"
                                            value={LiquidacionNomina.liquidacionfechacorte}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Tipo de Liquidación</Label>
                                        <Input
                                            type="select"
                                            name="liquidaciontipoliquidacion"
                                            value={LiquidacionNomina.liquidaciontipoliquidacion}
                                            onChange={handleChange}
                                        >
                                            <option value="quincenal">Quincenal</option>
                                            <option value="mensual">Mensual</option>


                                        </Input>
                                    </FormGroup>
                                </Col>


                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Año</Label>
                                        <Input
                                            type="text"
                                            name="liquidacionano"
                                            value={LiquidacionNomina.liquidacionano}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Mes</Label>
                                        <Input
                                            type="text"
                                            name="liquidacionmes"
                                            value={LiquidacionNomina.liquidacionmes}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Periodo</Label>
                                        <Input
                                            type="text"
                                            name="liquidacionperiodo"
                                            value={LiquidacionNomina.liquidacionperiodo}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Seleccion</Label>
                                        <Input
                                            type="select"
                                            name="seleccion"
                                            value={seleccion}
                                            onChange={(e) => {
                                                setseleccion(e.target.value)
                                                setcentrodecosto("-")
                                                setempleado("-")

                                            }}
                                        >
                                            <option value="todos">Todos</option>
                                            <option value="centrodecosto">Centro de Costo</option>
                                            <option value="empleado">Empleado</option>


                                        </Input>
                                    </FormGroup>
                                </Col>

                                {
                                    seleccion == "centrodecosto" &&
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Centro de Costo</Label>
                                            <Input
                                                type="text"
                                                name="centrodecosto"
                                                value={centrodecosto}
                                                onFocus={() => {
                                                    settitulobuscar("Centro de Costo")
                                                    setencabezadobuscar(["codigo", "nombre", "", ""])
                                                    setnamebuscado("centrodecosto")
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key == "Enter") {

                                                        setverModaltipodocumentos(true)
                                                        setdatosbuscados(CentrodeCosto)
                                                    }

                                                }}
                                            >


                                            </Input>
                                        </FormGroup>
                                    </Col>
                                }
                                {
                                    seleccion == "empleado" &&
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Empleado</Label>
                                            <Input
                                                type="text"
                                                name="empleado"
                                                value={empleado}
                                                onFocus={() => {
                                                    settitulobuscar("Empleados")
                                                    setencabezadobuscar(["codigo", "nombre", "", ""])
                                                    setnamebuscado("empleado")
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key == "Enter") {

                                                        setverModaltipodocumentos(true)
                                                        setdatosbuscados(Empleados)
                                                    }

                                                }}
                                            >


                                            </Input>
                                        </FormGroup>
                                    </Col>
                                }

                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Concepto Aprendizaje</Label>
                                        <Input
                                            type="text"
                                            name="liquidacionaprendizajeconcepto"
                                            value={LiquidacionNomina.liquidacionaprendizajeconcepto}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Festivo Aprendizaje</Label>
                                        <Input
                                            type="text"
                                            name="liquidacionaprendizajefestivo"
                                            value={LiquidacionNomina.liquidacionaprendizajefestivo}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Dominicales y Festivos Aprendizaje</Label>
                                        <Input
                                            type="text"
                                            name="liquidacionaprendizajedominicalesfestivos"
                                            value={LiquidacionNomina.liquidacionaprendizajedominicalesfestivos}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Buscar tituloencabezado={titulobuscar} filtrar={(coincidencia) => {
                                // filtrartipodocumentos(coincidencia)
                            }}
                                busqueda={datosbuscados} encabezado={encabezadobuscar}
                                codigo={encabezadobuscar[0]} nombre={encabezadobuscar[1]} dato={encabezadobuscar[2]} dato2={encabezadobuscar[3]}
                                verModal={verModaltipodocumentos} cerrarModal={() => {
                                    cerrartodo()

                                }} seleccionar={(seleccionado) => {
                                    cerrartodo()
                                    if (namebuscado == "empleado") {
                                        setempleado(seleccionado.codigo)

                                    }
                                    if (namebuscado == "centrodecosto") {
                                        setcentrodecosto(seleccionado.codigo)

                                    }
                                }} />
                        </Tab>
                    </Tabs>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={guardarCambios}>Liquidar</Button>
                    <Button color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
            <TipopdfNomina servidor={props.servidor} previsualizar={abrirvolantesdepago} cerrar={()=>{setabrirvolantesdepago(false)}} plantillapdf={plantillapdf} dataUser={JSON.parse(user)} user={user} sucursal={sucursal} empleadosliquidados={empleadosliquidados}/>
        </>
    );
};

export default LiquidacionNomina;

import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap";
import Swal from 'sweetalert2';
import Tabs from 'react-bootstrap/Tabs';
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab';
import Buscar from '../componentes/Buscar';

const modeloConcepto = {
    codigo: "",
    nombre: "",
    tipo: "",
    formaliquidacion: "",
    porcentajerecargo: 0.0,
    seguridadsocial: "",
    auxiliodetransporte: "",
    primas: "",
    vacaciones: "",
    cesantias: "",
    retencionenlafuente: "",
    parafiscalesyarl: "",
    indemnizacion: "",
    embargo: "",
    concdocente: "",
    conceptonomina: "",
    manejasueldominimo: "",
    opcion: "",
    opcion2: "",
    opcion3: "",
    opcion4: "",
    opcion5: "",
};

const Concepto = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Concepto, setConcepto] = useState(modeloConcepto);
    const [Conceptos, setConceptos] = useState([]);
    const [usuario, setDataUser] = useState([]);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [pendiente, setPendiente] = useState(true);
    const [verModalConcepto, setVerModalConcepto] = useState(false);

    const handleChange = (e) => {
        let value = e.target.value;
        var _Concepto = { ...Concepto };
        _Concepto[e.target.name] = value;
        setConcepto(_Concepto);
    }

    const obtenerConceptos = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/ConceptosNominaApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setConceptos(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let dt = JSON.parse(user);
        setDataUser(dt);
        obtenerConceptos('');
    }, []);

    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <Button color="primary" size="sm" className="mr-2"
                    onClick={() => abrirEditarModalConcepto(row)}
                >
                    <i className="fas fa-pen-alt"></i>
                </Button>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const abrirEditarModalConcepto = (data) => {
        var enviodata = { ...data };
        var _Concepto = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
            tipo: enviodata.tipo,
            formaliquidacion: enviodata.formaliquidacion,
            porcentajerecargo: enviodata.porcentajerecargo,
            seguridadsocial: enviodata.seguridadsocial,
            auxiliodetransporte: enviodata.auxiliodetransporte,
            primas: enviodata.primas,
            vacaciones: enviodata.vacaciones,
            cesantias: enviodata.cesantias,
            retencionenlafuente: enviodata.retencionenlafuente,
            parafiscalesyarl: enviodata.parafiscalesyarl,
            indemnizacion: enviodata.indemnizacion,
            embargo: enviodata.embargo,
            concdocente: enviodata.concdocente,
            conceptonomina: enviodata.conceptonomina,
            manejasueldominimo: enviodata.manejasueldominimo,
            opcion: enviodata.opcion,
            opcion2: enviodata.opcion2,
            opcion3: enviodata.opcion3,
            opcion4: enviodata.opcion4,
            opcion5: enviodata.opcion5,
        };
        setConcepto(_Concepto);
        setVerModalConcepto(!verModalConcepto);
        seteditar(true);
    }

    const cerrarModalConcepto = () => {
        setConcepto(modeloConcepto);
        setVerModalConcepto(!verModalConcepto);
        seteditar(false);
        setKey('Datos');
    }

    const guardarCambios = async () => {
        let response;
        if (!editar) {
            response = await fetch("" + props.servidor + "/ConceptosNominaApi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Concepto)
            });
        } else {
            response = await fetch("" + props.servidor + "/ConceptosNominaApi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Concepto)
            });
        }

        if (response.ok) {
            await obtenerConceptos('');
            setConcepto(modeloConcepto);
            setVerModalConcepto(!verModalConcepto);
            setKey('Datos');
            Swal.fire(
                'Guardado Exitosamente!',
                'El Concepto fue guardado exitosamente',
                'success'
            );
        } else {
            var textoerrores = await response.text();
            var jsonerrores = JSON.parse(textoerrores);
            var errores = jsonerrores.errors;
            let claves = Object.keys(errores);
            var stringerrores = "";
            for (let i = 0; i < claves.length; i++) {
                let clave = claves[i];
                if (errores[clave].length != 0) {
                    stringerrores = stringerrores + errores[clave][0] + " \n ";
                }
            }
            var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido');
            if (mensaje.includes('not be converted to System.Decimal')) {
                mensaje = 'Hay campos numéricos rellenados con letras';
            }
            Swal.fire(
                'Error al Guardar!',
                mensaje,
                'error'
            );
        }
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Conceptos
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <input type="text" id="buscardataConceptos" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerConceptos(document.getElementById('buscardataConceptos').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalConcepto(!verModalConcepto);
                                seteditar(false);
                            }}>Nuevo</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setCrearConcepto(false) }}>
                                    <i className="fa fa-times"></i>
                                </Button>
                            </Col>
                        </div>
                    </div>
                    <hr />
                    <DataTable
                        columns={columns}
                        data={Conceptos}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalConcepto}>
                <ModalHeader>
                    Concepto
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Codigo</Label>
                                        <Input disabled={editar} type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Concepto.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Nombre</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Concepto.nombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Tipo</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="tipo" value={Concepto.tipo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Forma de Liquidación</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="formaliquidacion" value={Concepto.formaliquidacion} >
                                            <option value="">-</option>
                                            <option value="valor">Valor</option>
                                            <option value="dia">Dia</option>
                                            <option value="horas">Horas</option>
                                            <option value="diaespecial">Dia Especial</option>
                                            <option value="horaespecial">Hora Especial</option>
                                            {/* si agregas algo aqui agregarlo en BuscarOpcionesBasicas en ConceptosNominaModel */}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Porcentaje Recargo</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="porcentajerecargo" value={Concepto.porcentajerecargo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Seguridad Social</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="seguridadsocial" value={Concepto.seguridadsocial} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Auxilio de Transporte</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="auxiliodetransporte" value={Concepto.auxiliodetransporte} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Primas</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="primas" value={Concepto.primas} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Vacaciones</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="vacaciones" value={Concepto.vacaciones} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Retenciones" title="Retenciones">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Cesantías</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="cesantias" value={Concepto.cesantias} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Retención en la Fuente</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="retencionenlafuente" value={Concepto.retencionenlafuente} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Parafiscales y ARL</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="parafiscalesyarl" value={Concepto.parafiscalesyarl} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Indemnización</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="indemnizacion" value={Concepto.indemnizacion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Embargo</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="embargo" value={Concepto.embargo} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Opciones" title="Opciones">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Concepto Docente</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="concdocente" value={Concepto.concdocente} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Concepto Nómina</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="conceptonomina" value={Concepto.conceptonomina} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Maneja Sueldo Mínimo</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="manejasueldominimo" value={Concepto.manejasueldominimo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Concepto Nomina Electronica</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion" value={Concepto.opcion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Concepto Seguridad Social</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="opcion2" value={Concepto.opcion2} >
                                            <option value="NAN">NINGUNA</option>
                                            <option value="AVP">AVP - APORTE VOLUNTARIO</option>
                                            <option value="IGE">IGE - INCAPACIDAD GENERAL</option>
                                            <option value="ING">ING - INGRESO</option>
                                            <option value="IRP">IRP - INCAPACIDAD POR ACCIDENTE DE TRABAJO</option>
                                            <option value="LMA">LMA - LICENCIA MATERNIDAD</option>
                                            <option value="LMP">LMA P - LICENCIA PARENTAL FLEXIBLE DE TIEMPO PARCIAL</option>
                                            <option value="LR">LR - LICENCIA REMUNERADA</option>
                                            <option value="REP">RET (P) - RETIRO DEL SISTEMA DE PENSIONES</option>
                                            <option value="RET">RET - RETIRO</option>
                                            <option value="SLN">SLN - SUSPENSIÓN TEMPORAL DEL CONTRATO DE TRABAJO O LICENCIA NO REMUNERADA</option>
                                            <option value="TAE">TAE - TRASLADO A OTRA EPS</option>
                                            <option value="TAP">TAP - TRASLADO A OTRA AFP</option>
                                            <option value="TDE">TDE - TRASLADO DESDE OTRA EPS</option>
                                            <option value="TDP">TDP - TRASLADO DESDE OTRA AFP</option>
                                            <option value="VAC">VAC - VACACIONES</option>
                                            <option value="VCT">VCT - VARIACION CENTROS DE TRABAJO</option>
                                            <option value="VSP">VSP - VARIACION PERMANENTE DE SALARIO</option>
                                            <option value="VST">VST - VARIACION TRANSITORIA DEL SALARIO</option>

                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Tipos de Concepto</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="opcion3" value={Concepto.opcion3} >
                                            <option value="">-</option>
                                            <option value="sueldo">Sueldo</option>
                                            <option value="auxiliodetransporte">Auxilio de Transporte</option>
                                            <option value="horasextras">Horas extras</option>
                                            <option value="recargonocturno">Recargo Nocturno</option>
                                            <option value="salud">Salud</option>
                                            <option value="pension">Pension</option>
                                            <option value="incapacidad">Pension</option>
                                            <option value="licencia">Pension</option>
                                            <option value="primas">Pension</option>
                                            <option value="vacaciones">Pension</option>
                                            <option value="cesantias">Pension</option>
                                            <option value="intereses de cesantias">Pension</option>
                                            {/* si agregas algo aqui agregarlo en BuscarOpcionesBasicas en ConceptosNominaModel */}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Concepto Seguridad Social</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="opcion4" value={Concepto.opcion4} >
                                            <option value="">-</option>
                                            <option value="variacion transitoria de salud ejemplo">Sueldo</option>
                                            {/* si agregas algo aqui agregarlo en BuscarOpcionesBasicas en ConceptosNominaModel */}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Opción 5</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion5" value={Concepto.opcion5} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalConcepto}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Concepto;

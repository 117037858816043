import React, { useState, useEffect, useRef, useContext } from "react";
import { UserContext } from "../context/UserProvider";

const ChatInput = ({ onSend, onReset }) => {
    const [message, setMessage] = useState("");
    const { user } = useContext(UserContext)

    const handleSend = () => {
        if (message.trim() !== "") {
            onSend(message);
            setMessage("");
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    return (
        <div style={styles.container}>
            <textarea
                style={styles.textarea}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Escribe tu mensaje..."
                rows={1}
            />
            <button style={styles.button} onClick={handleSend}>
                Enviar
            </button>
            <button style={styles.buttonreinicio} onClick={onReset}>
                Reiniciar
            </button>
        </div>
    );
};

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const [pendientes, setpendientes] = useState([]);
    const [todaslasempresas, settodaslasempresas] = useState([]);
    const { user } = useContext(UserContext)
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoyconslash, setfechahoyconslash] = useState(() => {
        const now = new Date();

        const dia = ("0" + now.getDate()).slice(-2);
        const mes = ("0" + (now.getMonth() + 1)).slice(-2); // Meses comienzan desde 0
        const anio = now.getFullYear();

        const horas = ("0" + now.getHours()).slice(-2);
        const minutos = ("0" + now.getMinutes()).slice(-2);
        const segundos = ("0" + now.getSeconds()).slice(-2);

        return `${mes}/${dia}/${anio} ${horas}:${minutos}:${segundos}`;
    });
    const [hora, sethora] = useState(() => {
        const now = new Date();

        const dia = ("0" + now.getDate()).slice(-2);
        const mes = ("0" + (now.getMonth() + 1)).slice(-2); // Meses comienzan desde 0
        const anio = now.getFullYear();

        const horas = ("0" + now.getHours()).slice(-2);
        const minutos = ("0" + now.getMinutes()).slice(-2);
        const segundos = ("0" + now.getSeconds()).slice(-2);

        return `${horas}:${minutos}:${segundos}`;
    });
    const [fechahoyconslashsinhora, setfechahoyconslashsinhora] = useState(() => {
        const now = new Date();

        const dia = ("0" + now.getDate()).slice(-2);
        const mes = ("0" + (now.getMonth() + 1)).slice(-2); // Meses comienzan desde 0
        const anio = now.getFullYear();

        const horas = ("0" + now.getHours()).slice(-2);
        const minutos = ("0" + now.getMinutes()).slice(-2);
        const segundos = ("0" + now.getSeconds()).slice(-2);

        return `${mes}/${dia}/${anio}`;
    });
    const [anioActual, setAnioActual] = useState(new Date().getFullYear());
    const [nombreMes, setNombreMes] = useState(() => {
        const nombresMeses = [
            "enero", "febrero", "marzo", "abril", "mayo", "junio",
            "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
        ];
        return nombresMeses[new Date().getMonth()]; // Mes actual
    });
    const buscarsoportedehoy = async (coincidencia) => {
        try {
            let dt = JSON.parse(user)

            var body = {
                "Action": "Find",
                "Properties": {
                    "Locale": "en-US",
                    "Timezone": "Pacific Standard Time",
                    "Selector": "Filter(SOPORTE FACTIN, And([ESTADO] = \"PENDIENTE\", Contains([CLIENTE], " + dt.empresa.empresa.nombre.replace("SAS", "").replace("S.A.S", "") + "),[TIPO DE SOPORTE]=2))"
                },
                "Rows": []
            }
            let response = await fetch("https://api.appsheet.com/api/v2/apps/" +
                "a5685b02-6140-42e2-85ba-179ca3f50f26/tables/SOPORTE FACTIN/Action?applicationAccessKey=a5685b02-6140-42e2-85ba-179ca3f50f26", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "ApplicationAccessKey": "V2-VCxaN-ayIWY-vMb81-43TZX-fk8iT-eKhJV-j9fSa-BSoKR"
                },
                body: JSON.stringify(body) // Convertir el cuerpo a JSON
            });

            if (response.ok) {
                let data = await response.json()
                setpendientes(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodaslasempresas = async (coincidencia) => {
        try {
            let dt = JSON.parse(user)

            var body = {
                "Action": "Find",
                "Properties": {
                    "Locale": "en-US",
                    "Timezone": "Pacific Standard Time",
                },
                "Rows": []
            }
            let response = await fetch("https://api.appsheet.com/api/v2/apps/" +
                "a5685b02-6140-42e2-85ba-179ca3f50f26/tables/CLIENTES/Action?applicationAccessKey=a5685b02-6140-42e2-85ba-179ca3f50f26", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "ApplicationAccessKey": "V2-VCxaN-ayIWY-vMb81-43TZX-fk8iT-eKhJV-j9fSa-BSoKR"
                },
                body: JSON.stringify(body) // Convertir el cuerpo a JSON
            });

            if (response.ok) {
                let data = await response.json()
                settodaslasempresas(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodoslossoportesdehoy = async (coincidencia) => {
        try {
            let dt = JSON.parse(user)

            var body = {
                "Action": "Find",
                "Properties": {
                    "Locale": "en-US",
                    "Timezone": "Pacific Standard Time",
                    "Selector": "Filter(SOPORTE FACTIN, And([ESTADO] = \"PENDIENTE\", [FECHA DE REGISTRO] = DATE(\"" + fechahoy + "\"),[TIPO DE SOPORTE]=2))"
                },
                "Rows": []
            }
            let response = await fetch("https://api.appsheet.com/api/v2/apps/" +
                "a5685b02-6140-42e2-85ba-179ca3f50f26/tables/SOPORTE FACTIN/Action?applicationAccessKey=a5685b02-6140-42e2-85ba-179ca3f50f26", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "ApplicationAccessKey": "V2-VCxaN-ayIWY-vMb81-43TZX-fk8iT-eKhJV-j9fSa-BSoKR"
                },
                body: JSON.stringify(body) // Convertir el cuerpo a JSON
            });

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodaslascapacitacionesdehoy = async (coincidencia) => {
        try {
            let dt = JSON.parse(user)

            var body = {
                "Action": "Find",
                "Properties": {
                    "Locale": "en-US",
                    "Timezone": "Pacific Standard Time",
                    "Selector": "Filter(CAPACITACIONES FACTIN, And([ESTADO] = \"PENDIENTE\", [FECHA DE REGISTRO] = DATE(\"" + fechahoy + "\"),[TIPO DE SOPORTE]=2))"
                },
                "Rows": []
            }
            let response = await fetch("https://api.appsheet.com/api/v2/apps/" +
                "a5685b02-6140-42e2-85ba-179ca3f50f26/tables/CAPACITACIONES FACTIN/Action?applicationAccessKey=a5685b02-6140-42e2-85ba-179ca3f50f26", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "ApplicationAccessKey": "V2-VCxaN-ayIWY-vMb81-43TZX-fk8iT-eKhJV-j9fSa-BSoKR"
                },
                body: JSON.stringify(body) // Convertir el cuerpo a JSON
            });

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const guardarsoporte = async (mensaje) => {
        try {
            let dt = JSON.parse(user)

            var body = {
                "Action": "Add",
                "Properties": {
                    "Locale": "en-US",
                    "Timezone": "Pacific Standard Time",
                },
                "Rows": [mensaje]
            }
            console.log(JSON.stringify(body))
            let response = await fetch("https://api.appsheet.com/api/v2/apps/" +
                "a5685b02-6140-42e2-85ba-179ca3f50f26/tables/SOPORTE FACTIN/Action?applicationAccessKey=a5685b02-6140-42e2-85ba-179ca3f50f26", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "ApplicationAccessKey": "V2-VCxaN-ayIWY-vMb81-43TZX-fk8iT-eKhJV-j9fSa-BSoKR"
                },
                body: JSON.stringify(body) // Convertir el cuerpo a JSON
            });

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleSend = async (message) => {
        const currentDate = new Date();
        const currentHour = currentDate.getHours();

        // Validamos si la hora está fuera del rango permitido: antes de las 8 AM o después de las 5 PM
        if (currentHour < 8 || currentHour >= 17) {
            bot = `✅ ¡Perfecto, ${message}! 📞 Ahora, por favor, comparte el número al que podemos llamarte.`;
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: message, type: "user" },
                { text: bot, type: "auto" },
            ]);
            return;
        }
        let dt = JSON.parse(user)

        var bot = ""
        if (messages.length == 0) {
            bot = `¡Gracias por comunicarte con Factin!
    Soy Jerry, tu asesor virtual. Estoy aquí para ayudarte.
    Por favor, indícanos cómo podemos asistirte hoy:
    
    1️⃣ Soporte Virtual
    
    📩 Responde con el número de la opción deseada, y con gusto atenderé tu solicitud.
    
    ¡Estamos comprometidos con ofrecerte el mejor servicio! 🌟`;
        }
        if (messages.length == 2 && message == "1") {
            bot = `¡Entendido! 😊
        Has seleccionado **Soporte Virtual**.  
        Por favor, cuéntame brevemente el problema o consulta que tienes, y haré todo lo posible para ayudarte.  
        🔧 Estamos aquí para resolverlo contigo.`;

        }

        if (messages.length == 2 && (message != "1")) {
            bot = `❌ Opción no válida. Por favor, presiona "Reiniciar" para intentarlo de nuevo.`;
        }

        if (pendientes.length > 0) {
            bot = `📌 Tienes un soporte pendiente. En breve, uno de nuestros asesores se pondrá en contacto contigo. ¡Gracias por tu paciencia!`;
        }
        if (messages.length == 4) {
            bot = `👨‍💼 Por favor, indícanos el nombre de la persona con la que nos comunicaremos.`;
        }
        if (messages.length == 6) {
            bot = `✅ ¡Perfecto, ${message}! 📞 Ahora, por favor, comparte el número al que podemos llamarte.`;
        }
        if (messages.length == 8) {
            if (dt.empresa.empresa.nit == "Jerry") {
                bot = `✅ ¡Perfecto, ${messages[6].text}! 👨‍💼 Ahora, por favor, comparte el nit de la empresa a la que perteneces.`;

            } else {
                var tipo = ""
                messages.forEach(element => {
                    if (element.text == "2") {
                        tipo = "2"
                    }
                    if (element.text == "1") {
                        tipo = "1"
                    }
                });
                if (tipo == "1") {
                    var todossoportesdehoy = await buscartodoslossoportesdehoy()
                    const groupedByUserID = todossoportesdehoy.reduce((acc, item) => {
                        const userID = item.UserID || "Sin UserID"; // Manejar valores nulos o no definidos
                        if (!acc[userID]) {
                            if (userID != "US=0006 MIGUEL JULIO" && userID != "US=0011 ERWIN PERTUZ" && userID != "US=0007 ARAMIS DE LA TORRE" && userID != "US=0002 JOSÉ ALMANZA") {
                                acc[userID] = [];

                            }
                        }
                        if (userID != "US=0006 MIGUEL JULIO" && userID != "US=0011 ERWIN PERTUZ" && userID != "US=0007 ARAMIS DE LA TORRE" && userID != "US=0002 JOSÉ ALMANZA") {
                            acc[userID].push(item);


                        }
                        return acc;
                    }, {});
                    var listadesoportistas = ["US=0004 CRISTIAN WEZ", "US=0003 JESÚS RÚA"];
                    listadesoportistas.forEach(soport => {
                        if (!groupedByUserID.hasOwnProperty(soport)) {
                            groupedByUserID[soport] = [];

                        }
                    });
                    let minGroup = { userID: null, count: Infinity }; // Inicializa con valores altos

                    for (const userID in groupedByUserID) {
                        const count = groupedByUserID[userID].length;
                        if (count < minGroup.count) {
                            minGroup = { userID, count };
                        }
                    }

                    console.log(groupedByUserID)
                    console.log(minGroup)
                    var empresabusc = todaslasempresas.find(e => e.NIT == dt.empresa.empresa.nit)
                    var soporteparaenviar = {
                        "CLIENTE": empresabusc['ID CLIENTE'],
                        "CONTACTO": messages[6].text,
                        "TELEFONO": message,
                        "OBSERVACION": messages[4].text,
                        "UserID": minGroup.userID,
                        "HORA DE REGISTRO": hora,
                        "FECHA DE REGISTRO": fechahoyconslashsinhora,
                        "ESTADO": "PENDIENTE",
                        "OBSERVACIÓN SOLUCION": "",
                        "FECHA DE SOLUCIÓN": "",
                        "HORA DE SOLUCIÓN": "",
                        "CORREO ELECTRONICO": "",
                        "ESTADO DE EMPRESA": "ACTIVA",
                        "PRUDUCTOS": "",
                        "COMENTARIO ADICIONAL": "",
                        "MES": anioActual + " " + nombreMes,
                        "TIENE NR": "Nube",
                        "REGISTRADO POR": "US=0012 JERRY",
                        "TIPO DE SOPORTE": "2",
                        "FECHA Y HORA INGRESO SOPORTE": fechahoyconslash,
                        "Nit": dt.empresa.empresa.nit,
                    }
                    var enviodemensaje = await guardarsoporte(soporteparaenviar)
                    bot = `¡Perfecto ` + messages[6].text + `! 📚  
        Su Soporte se solicito correctamente. Numero de ticket: `+ enviodemensaje.Rows[0].ID;
                    buscarsoportedehoy()
                }


                if (tipo == "") {
                    bot = messages[6].text + ` Tiene que escoger una opcion Valida. Precione REINICIAR e intente nuevamente`

                }
            }
        }
        if (messages.length == 10) {
            if (dt.empresa.empresa.nit == "Jerry") {
                var tipo = ""
                messages.forEach(element => {
                    if (element.text == "2") {
                        tipo = "2"
                    }
                    if (element.text == "1") {
                        tipo = "1"
                    }
                });
                if (tipo == "1") {
                    var todossoportesdehoy = await buscartodoslossoportesdehoy()
                    const groupedByUserID = todossoportesdehoy.reduce((acc, item) => {
                        const userID = item.UserID || "Sin UserID"; // Manejar valores nulos o no definidos
                        if (!acc[userID]) {
                            if (userID != "US=0006 MIGUEL JULIO" && userID != "US=0011 ERWIN PERTUZ" && userID != "US=0007 ARAMIS DE LA TORRE" && userID != "US=0004 CRISTIAN WEZ") {
                                acc[userID] = [];

                            }
                        }
                        if (userID != "US=0006 MIGUEL JULIO" && userID != "US=0011 ERWIN PERTUZ" && userID != "US=0007 ARAMIS DE LA TORRE" && userID != "US=0004 CRISTIAN WEZ") {
                            acc[userID].push(item);


                        }
                        return acc;
                    }, {});

                    var listadesoportistas = ["US=0002 JOSÉ ALMANZA", "US=0003 JESÚS RÚA"];
                    listadesoportistas.forEach(soport => {
                        if (!groupedByUserID.hasOwnProperty(soport)) {
                            groupedByUserID[soport] = [];

                        }
                    });


                    let minGroup = { userID: null, count: Infinity }; // Inicializa con valores altos

                    for (const userID in groupedByUserID) {
                        const count = groupedByUserID[userID].length;
                        if (count < minGroup.count) {
                            minGroup = { userID, count };
                        }
                    }

                    console.log(groupedByUserID)
                    console.log(minGroup)
                    var empresabusc = todaslasempresas.find(e => e.NIT == message)
                    var soporteparaenviar = {
                        "CLIENTE": empresabusc['ID CLIENTE'],
                        "CONTACTO": messages[6].text,
                        "TELEFONO": messages[8].text,
                        "OBSERVACION": messages[4].text,
                        "UserID": minGroup.userID,
                        "HORA DE REGISTRO": hora,
                        "FECHA DE REGISTRO": fechahoyconslashsinhora,
                        "ESTADO": "PENDIENTE",
                        "OBSERVACIÓN SOLUCION": "",
                        "FECHA DE SOLUCIÓN": "",
                        "HORA DE SOLUCIÓN": "",
                        "CORREO ELECTRONICO": "",
                        "ESTADO DE EMPRESA": "ACTIVA",
                        "PRUDUCTOS": "",
                        "COMENTARIO ADICIONAL": "",
                        "MES": anioActual + " " + nombreMes,
                        "TIENE NR": "Nube",
                        "REGISTRADO POR": "US=0012 JERRY",
                        "TIPO DE SOPORTE": "2",
                        "FECHA Y HORA INGRESO SOPORTE": fechahoyconslash,
                        "Nit": message,
                    }
                    var enviodemensaje = await guardarsoporte(soporteparaenviar)
                    bot = `¡Perfecto ` + messages[6].text + `! 📚  
        Su Soporte se solicito correctamente. Numero de ticket: `+ enviodemensaje.Rows[0].ID;
                    buscarsoportedehoy()
                }


                if (tipo == "") {
                    bot = messages[6].text + ` Tiene que escoger una opcion Valida. Precione REINICIAR e intente nuevamente`

                }
            } else {
                bot = messages[6].text + ` Tiene que escoger una opcion Valida. Precione REINICIAR e intente nuevamente`

            }
        }
        setMessages((prevMessages) => [
            ...prevMessages,
            { text: message, type: "user" },
            { text: bot, type: "auto" },
        ]);
    };
    useEffect(() => {
        buscarsoportedehoy()
        buscartodaslasempresas()
    }, [])
    // useEffect(() => {
    //     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // }, [messages]);
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [messages]);
    return (
        <div style={styles.chatContainer}>
            <div style={styles.messagesContainer} ref={messagesEndRef} >
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        style={{
                            ...styles.message,
                            alignSelf: msg.type === "user" ? "flex-end" : "flex-start",
                            backgroundColor: msg.type === "auto" ? "#ffd1d1" : "#e9f5ff",
                        }}
                    >
                        {msg.text}
                    </div>
                ))}
                {/* <div ref={messagesEndRef} /> */}
            </div>
            <ChatInput onSend={handleSend} onReset={() => {
                buscarsoportedehoy()
                setMessages([])
            }} />
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        padding: "10px",
        backgroundColor: "#f5f5f5",
        borderTop: "1px solid #ddd",
    },
    textarea: {
        flex: 1,
        padding: "10px",
        fontSize: "16px",
        borderRadius: "8px",
        border: "1px solid #ddd",
        outline: "none",
        resize: "none",
        overflow: "hidden",
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "16px",
        borderRadius: "8px",
        border: "none",
        cursor: "pointer",
        transition: "background-color 0.3s",
    },
    buttonreinicio: {
        backgroundColor: "red",
        color: "white",
        padding: "10px 20px",
        fontSize: "16px",
        borderRadius: "8px",
        border: "none",
        cursor: "pointer",
        transition: "background-color 0.3s",
    },
    chatContainer: {
        display: "flex",
        flexDirection: "column",
        height: "80vh",
        maxWidth: "600px",
        margin: "0 auto",
        border: "1px solid #ddd",
        borderRadius: "8px",
        overflow: "hidden",
    },
    messagesContainer: {
        flex: 1,
        padding: "10px",
        overflowY: "auto", // Habilita el scroll vertical
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        whiteSpace: "pre-wrap",
        maxHeight: "400px", // Limita la altura del contenedor
        border: "1px solid #ddd", // (Opcional) para visualizar los límites del contenedor
        borderRadius: "8px", // (Opcional) para un diseño más moderno
    },
    message: {
        padding: "10px",
        margin: "5px 0",
        borderRadius: "8px",
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
        maxWidth: "70%",
        backgroundColor: "#f9f9f9", // (Opcional) un color para contrastar con el fondo del contenedor
        wordBreak: "break-word", // Permite que los mensajes largos se ajusten a la línea
    },
};

export default Chat;


import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from 'sweetalert2'
import { FormatMoney } from 'format-money-js'
import Autosuggest from 'react-autosuggest';
import { useContext, useState, useEffect, useRef } from "react";
import "./css/Venta.css"
import { Event, event } from "jquery";
import { jsPDF } from "jspdf";
import Icono from './images/blanco.png'
import { QRCode } from 'react-qrcode-logo';
import { renderToStaticMarkup } from "react-dom/server"
import ReactDOMServer from 'react-dom/server';
import { Autocomplete, TextField } from '@mui/material';
import { Try } from "@mui/icons-material";
const tabespacio = '\u00A0';
const modeloPais = {
    codigo: "",
    nombre: "",
}
const Tipopodf = (props) => {
    const [fm, setfm] = useState(new FormatMoney({ symbol: '' }));
    const [fm1, setfm1] = useState(new FormatMoney({ symbol: '', decimals: 1 }));
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [ivadiscriminado, setivadiscriminado] = useState([])
    const [Conceptos, setConceptos] = useState([]);

    useEffect(() => {
        obtenerConceptos("")


    }, [])
    const obtenerConceptos = async (coincidencia) => {
        try {
            let dt = JSON.parse(props.user);
            let response = await fetch(props.servidor + '/ConceptosNominaApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setConceptos(data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    function calcularDigitoVerificacion(myNit) {
        try {
            var vpri,
                x,
                y,
                z;

            // Se limpia el Nit
            myNit = myNit.replace(/\s/g, ""); // Espacios
            myNit = myNit.replace(/,/g, ""); // Comas
            myNit = myNit.replace(/\./g, ""); // Puntos
            myNit = myNit.replace(/-/g, ""); // Guiones

            // Se valida el nit
            if (isNaN(myNit)) {
                console.log("El nit/cédula '" + myNit + "' no es válido(a).");
                return "";
            };

            // Procedimiento
            vpri = new Array(16);
            z = myNit.length;

            vpri[1] = 3;
            vpri[2] = 7;
            vpri[3] = 13;
            vpri[4] = 17;
            vpri[5] = 19;
            vpri[6] = 23;
            vpri[7] = 29;
            vpri[8] = 37;
            vpri[9] = 41;
            vpri[10] = 43;
            vpri[11] = 47;
            vpri[12] = 53;
            vpri[13] = 59;
            vpri[14] = 67;
            vpri[15] = 71;

            x = 0;
            y = 0;
            for (var i = 0; i < z; i++) {
                y = (myNit.substr(i, 1));
                // console.log ( y + "x" + vpri[z-i] + ":" ) ;

                x += (y * vpri[z - i]);
                // console.log ( x ) ;
            }

            y = x % 11;
            // console.log ( y ) ;

            return (y > 1) ? 11 - y : y;
        } catch (error) {
            return ""
        }
    }

    const buscarnombremediopago = (codigo) => {
        var medd = props.mediopagos.filter(p => p.codigo == codigo)
        if (medd.length != 0) {
            return medd[0].nombre
        } else {
            return "Transferencia"
        }
    }

    const valortotalliquidacion = (empleado) => {
        var total = 0
        empleado.liquidacion.forEach(element => {
            if (element.devengadodeducido == "devengado") {
                total = total + element.valor

            } else {
                total = total - element.valor

            }
        });
        return fm3.from(total)
    }

    return (
        <Modal size="lg" isOpen={props.previsualizar} onOpened={(e) => {

        }}>
            <ModalHeader>
                Comprobante De Nomina
            </ModalHeader>
            {props.empleadosliquidados.map((data) => {

                return <ModalBody id={"pruebapdfventaelectronica"} >
                    <Row >
                        <Col sm={12}>
                            <Row>
                                <Col sm={4} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal' }}>
                                    {props.plantillapdf.mostrarlogo && <img src={"imagen/" + (props.sucursal.nombrecomercial ? (props.sucursal.nombrecomercial.toLowerCase().includes(".png") || props.sucursal.nombrecomercial.toLowerCase().includes(".jpg") ? props.sucursal.nombrecomercial : JSON.parse(props.user).empresa.config.ruta_logo) : JSON.parse(props.user).empresa.config.ruta_logo)} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                </Col>
                                <Col sm={4} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                    {props.plantillapdf.nombrereal && JSON.parse(props.user).empresa.empresa.nombre} {props.plantillapdf.nombrereal && <br />}
                                    {!props.plantillapdf.nombrereal && (props.sucursal && props.sucursal.nit != "" ? props.sucursal.nombre : JSON.parse(props.user).empresa.config.encabezado_pdf)} {!props.plantillapdf.nombrereal && <br />}
                                    {props.plantillapdf.nit && ("Nit: " + (props.sucursal && props.sucursal.nit != "" ? props.sucursal.nit : JSON.parse(props.user).empresa.empresa.nit + " - " + calcularDigitoVerificacion(JSON.parse(props.user).empresa.empresa.nit)))} {props.plantillapdf.nit && <br />}{/* {"Direccion: " + JSON.parse(props.user).empresa.empresa.direccion}<br /> */}
                                    {props.plantillapdf.telefono && ("Telefonos: " + (props.sucursal && props.sucursal.nit != "" ? props.sucursal.telefono : JSON.parse(props.user).empresa.empresa.telefono) + " - " + (props.sucursal && props.sucursal.nit != "" ? props.sucursal.celular : JSON.parse(props.user).empresa.empresa.celular))}{props.plantillapdf.telefono && (<br />)}



                                </Col>
                                <Col sm={4} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra }}>

                                </Col>
                            </Row>


                        </Col>
                    </Row>
                    <br />
                    <Row style={{
                        border: "3px solid #188BAD",
                        borderRadius: "10px"
                    }}>
                        <Col sm={12}>
                            <Row>
                                <Col sm={4} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                    {"Nit o C.C: " + data.empleado.cedula}   <br />
                                    {"Sueldo Basico: " + fm1.from(data.empleado.sueldo)}      <br />
                                    {"Cargo: " + data.empleado.talla1}               <br />
                                </Col>

                                <Col sm={4} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                    {"Apellidos y Nombres: " + data.empleado.nombre}  <br />
                                    {"Direccion: " + data.empleado.direccion}         <br />
                                    {"Entidad Financiera: " + data.empleado.banco}     <br />
                                </Col>
                                <Col sm={4} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                    {"Telefono: " + data.empleado.telefono1}         <br />
                                    {"Tipo de Cuenta: " + data.empleado.tipocuenta}  <br />
                                    {"Nro cta ahorros: " + data.empleado.cuenta}      <br />
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                    <Row style={{
                        border: "3px solid #188BAD",
                        borderRadius: "10px"
                    }}>
                        <Col sm={12} style={{
                            border: "3px solid #188BAD",
                            borderRadius: "10px"
                        }}>


                            <Row>
                                <Col sm={1} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                    {"C.Costo"}

                                </Col>

                                <Col sm={1} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                    {"Concepto"}
                                </Col>
                                <Col sm={4} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                    {"Descripcion"}
                                </Col>
                                <Col sm={1} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                    {"Dias"}
                                </Col>
                                <Col sm={1} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                    {"Horas"}
                                </Col>
                                <Col sm={2} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                    {"Devengados"}
                                </Col>
                                <Col sm={2} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                    {"Deducciones"}
                                </Col>
                            </Row>
                        </Col>

                        {
                            data.liquidacion.map((liqui) => {
                                return <Col sm={12}>
                                    <Row>
                                        <Col sm={1} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                            {liqui.centrodecosto}

                                        </Col>

                                        <Col sm={1} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                            {liqui.concepto}
                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                            {Conceptos.find(p => p.codigo == liqui.concepto)?.nombre || "Sin nombre"}
                                        </Col>
                                        <Col sm={1} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                            {liqui.opcion == "dia" && liqui.dias}
                                        </Col>
                                        <Col sm={1} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                            {liqui.opcion == "horas" && liqui.horas}
                                        </Col>
                                        <Col sm={2} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                            {liqui.devengadodeducido == "devengado" && fm3.from(liqui.valor)}
                                        </Col>
                                        <Col sm={2} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, fontWeight: 'normal', color: props.plantillapdf.colorletra, fontFamily: 'Arial,monospace' }}>
                                            {liqui.devengadodeducido == "deducido" && fm3.from(liqui.valor)}
                                        </Col>
                                    </Row>


                                </Col>

                            })

                        }
                        <br />
                        <div style={{ color: props.plantillapdf.colorletra }}>
                            {"Neto Empleado: " + valortotalliquidacion(data)}

                        </div>
                    </Row>

                </ModalBody>

            })


            }



            <ModalFooter>

                <Button size="sm" color="primary" onClick={() => {

                }}>Enviar Correo</Button>
                <Button size="sm" color="primary" onClick={() => {


                }}>PDF</Button>
                <Button size="sm" color="primary" onClick={() => {
                    props.cerrar()
                }}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}
export default Tipopodf;

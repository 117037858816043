import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap";
import Swal from 'sweetalert2';
import Tabs from 'react-bootstrap/Tabs';
import { UserContext } from '../context/UserProvider';
import Tab from 'react-bootstrap/Tab';

const modeloParametrosNomina = {
    id: 0,
    salariominimo: 0,
    auxiliotransporte: 0,
    limiteauxiliotransporte: 0,
    diasminimopagovacaciones: 0,
    porcentajeanualinteresescesantias: 0,
    porcentajeretefuente: 0,
    porcentajearetenerporexcendete: 0,
    porcentajemaximoexento: 0,
    valormaximoexcento: 0,
    porcentajeeducaacionysalud: 0,
    porcentajelicenciaeg: 0,
    valorvivienda: 0,
    valorretefuenteuvt: 0,
    horassemanales: 0,
    norealizarcontabilizacionencierres: "",
    liquidacionporcentrodecostosparcial: "",
    tipoderedondeo: "",
    cuentacausacion: "",
    transfiereenlinea: "",
    saludempleador: 0,
    saludempleado: 0,
    saludlimiteinferior: 0,
    saludlimitesuperior: 0,
    pensionempleador: 0,
    pensionempleado: 0,
    pensionlimiteinferior: 0,
    pensionlimitesuperior: 0,
    fondosolidaridadempleado: 0,
    fondosolidaridadlimiteinferior: 0,
    fondosolidaridadlimitesuperior: 0,
    parafiscalescajacompensacion: 0,
    parafiscalesicbf: 0,
    parafiscalessena: 0,
    parafiscalesescuelasindustriales: 0,
    exoneradodeaportesparafiscales: "",
    consecutivonomina: "",
};

const ParametrosNomina = (props) => {
    const { user } = useContext(UserContext);
    const [ParametrosNomina, setParametrosNomina] = useState(modeloParametrosNomina);
    const [Parametros, setParametros] = useState([]);
    const [editar, setEditar] = useState(false);
    const [verModal, setVerModal] = useState(false);
    const [key, setKey] = useState('Datos');
    const [pendiente, setPendiente] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setParametrosNomina({ ...ParametrosNomina, [name]: value });
    };

    const obtenerParametros = async () => {
        try {
            const dt = JSON.parse(user);
            const response = await fetch(`${props.servidor}/ParametrosNominaapi?llave=${dt.usuario.llaveempresa}`);

            if (response.ok) {
                const data = await response.json();
                setParametros(data);
                setPendiente(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        obtenerParametros();
    }, [user]);

    const columns = [
        {
            name: 'Salario Minimo',
            selector: row => row.salariominimo,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <Button color="primary" size="sm" onClick={() => abrirEditarModal(row)}>
                    <i className="fas fa-pen-alt"></i>
                </Button>
            ),
        },
    ];

    const abrirEditarModal = (data) => {
        setParametrosNomina(data);
        setVerModal(true);
        setEditar(true);
    };

    const cerrarModal = () => {
        setParametrosNomina(modeloParametrosNomina);
        setVerModal(false);
        setEditar(false);
    };

    const guardarCambios = async () => {
        try {
            const dt = JSON.parse(user);
            const method = editar ? 'PUT' : 'POST';
            const response = await fetch(`${props.servidor}/ParametrosNominaapi?llave=${dt.usuario.llaveempresa}`, {
                method,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify(ParametrosNomina),
            });

            if (response.ok) {
                await obtenerParametros();
                cerrarModal();
                Swal.fire('Guardado Exitosamente!', 'Los parámetros de nómina fueron guardados exitosamente', 'success');
            } else {
                const errorText = await response.text();
                const errorJson = JSON.parse(errorText);
                const errores = errorJson.errors;

                const mensajes = Object.values(errores).flat().join('\n');
                Swal.fire('Error al Guardar!', mensajes, 'error');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: 'white' }}>Parámetros de Nómina</CardHeader>
                <CardBody>
                    <div className="mb-3">
                        <InputGroup>
                            <Input id="buscar" placeholder="Buscar" />
                            <Button color="primary" onClick={obtenerParametros}>Buscar</Button>
                            {
                                Parametros.length == 0 && <Button color="success" onClick={() => setVerModal(true)}>Nuevo</Button>
                            }

                        </InputGroup>
                    </div>
                    <DataTable
                        columns={columns}
                        data={Parametros}
                        progressPending={pendiente}
                        pagination
                        customStyles={{
                            headCells: {
                                style: { fontSize: '13px', fontWeight: 800 },
                            },
                            headRow: {
                                style: { backgroundColor: '#eee' },
                            },
                        }}
                    />
                </CardBody>
            </Card>

            <Modal size="lg" style={{ maxWidth: '1000px', width: '100%' }} isOpen={verModal} toggle={cerrarModal}>
                <ModalHeader toggle={cerrarModal}>Parámetros de Nómina</ModalHeader>
                <ModalBody>
                    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Salario Mínimo</Label>
                                        <Input
                                            type="number"
                                            name="salariominimo"
                                            value={ParametrosNomina.salariominimo}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Auxilio Transporte</Label>
                                        <Input
                                            type="number"
                                            name="auxiliotransporte"
                                            value={ParametrosNomina.auxiliotransporte}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Límite Auxilio Transporte</Label>
                                        <Input
                                            type="number"
                                            name="limiteauxiliotransporte"
                                            value={ParametrosNomina.limiteauxiliotransporte}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Días Mínimos Pago Vacaciones</Label>
                                        <Input
                                            type="number"
                                            name="diasminimopagovacaciones"
                                            value={ParametrosNomina.diasminimopagovacaciones}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Porcentaje Anual Intereses Cesantías</Label>
                                        <Input
                                            type="number"
                                            name="porcentajeanualinteresescesantias"
                                            value={ParametrosNomina.porcentajeanualinteresescesantias}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Porcentaje Retefuente</Label>
                                        <Input
                                            type="number"
                                            name="porcentajeretefuente"
                                            value={ParametrosNomina.porcentajeretefuente}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Otros Datos" title="Otros Datos">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Porcentaje a Retener por Excedente</Label>
                                        <Input
                                            type="number"
                                            name="porcentajearetenerporexcendete"
                                            value={ParametrosNomina.porcentajearetenerporexcendete}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Porcentaje Máximo Exento</Label>
                                        <Input
                                            type="number"
                                            name="porcentajemaximoexento"
                                            value={ParametrosNomina.porcentajemaximoexento}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Valor Máximo Exento</Label>
                                        <Input
                                            type="number"
                                            name="valormaximoexcento"
                                            value={ParametrosNomina.valormaximoexcento}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Porcentaje Educación y Salud</Label>
                                        <Input
                                            type="number"
                                            name="porcentajeeducaacionysalud"
                                            value={ParametrosNomina.porcentajeeducaacionysalud}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Porcentaje Licencia EG</Label>
                                        <Input
                                            type="number"
                                            name="porcentajelicenciaeg"
                                            value={ParametrosNomina.porcentajelicenciaeg}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Valor Vivienda</Label>
                                        <Input
                                            type="number"
                                            name="valorvivienda"
                                            value={ParametrosNomina.valorvivienda}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Horas y Redondeo" title="Horas y Redondeo">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Valor Retefuente UVT</Label>
                                        <Input
                                            type="number"
                                            name="valorretefuenteuvt"
                                            value={ParametrosNomina.valorretefuenteuvt}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Horas Semanales</Label>
                                        <Input
                                            type="number"
                                            name="horassemanales"
                                            value={ParametrosNomina.horassemanales}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>No Realizar Contabilización en Cierres</Label>
                                        <Input
                                            type="text"
                                            name="norealizarcontabilizacionencierres"
                                            value={ParametrosNomina.norealizarcontabilizacionencierres}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Liquidación por Centro de Costos Parcial</Label>
                                        <Input
                                            type="text"
                                            name="liquidacionporcentrodecostosparcial"
                                            value={ParametrosNomina.liquidacionporcentrodecostosparcial}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tipo de Redondeo</Label>
                                        <Input
                                            type="text"
                                            name="tipoderedondeo"
                                            value={ParametrosNomina.tipoderedondeo}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Cuenta Causación</Label>
                                        <Input
                                            type="text"
                                            name="cuentacausacion"
                                            value={ParametrosNomina.cuentacausacion}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Transfiere en Línea</Label>
                                        <Input
                                            type="text"
                                            name="transfiereenlinea"
                                            value={ParametrosNomina.transfiereenlinea}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Salud,Pension y Solidaridad" title="Salud,Pension y Solidaridad">
                            <Row>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Salud Empleador</Label>
                                        <Input
                                            type="number"
                                            name="saludempleador"
                                            value={ParametrosNomina.saludempleador}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Salud Empleado</Label>
                                        <Input
                                            type="number"
                                            name="saludempleado"
                                            value={ParametrosNomina.saludempleado}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Salud Límite Inferior</Label>
                                        <Input
                                            type="number"
                                            name="saludlimiteinferior"
                                            value={ParametrosNomina.saludlimiteinferior}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Salud Límite Superior</Label>
                                        <Input
                                            type="number"
                                            name="saludlimitesuperior"
                                            value={ParametrosNomina.saludlimitesuperior}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>


                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Pensión Empleador</Label>
                                        <Input
                                            type="number"
                                            name="pensionempleador"
                                            value={ParametrosNomina.pensionempleador}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Pensión Empleado</Label>
                                        <Input
                                            type="number"
                                            name="pensionempleado"
                                            value={ParametrosNomina.pensionempleado}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Pensión Límite Inferior</Label>
                                        <Input
                                            type="number"
                                            name="pensionlimiteinferior"
                                            value={ParametrosNomina.pensionlimiteinferior}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Pensión Límite Superior</Label>
                                        <Input
                                            type="number"
                                            name="pensionlimitesuperior"
                                            value={ParametrosNomina.pensionlimitesuperior}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>

                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Fondo Solidaridad Empleado</Label>
                                        <Input
                                            type="number"
                                            name="fondosolidaridadempleado"
                                            value={ParametrosNomina.fondosolidaridadempleado}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Fondo Solidaridad Límite Inferior</Label>
                                        <Input
                                            type="number"
                                            name="fondosolidaridadlimiteinferior"
                                            value={ParametrosNomina.fondosolidaridadlimiteinferior}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Fondo Solidaridad Límite Superior</Label>
                                        <Input
                                            type="number"
                                            name="fondosolidaridadlimitesuperior"
                                            value={ParametrosNomina.fondosolidaridadlimitesuperior}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Parafiscales y Consecutivo" title="Parafiscales y Consecutivo">
                            <Row>

                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Parafiscales Caja Compensación</Label>
                                        <Input
                                            type="number"
                                            name="parafiscalescajacompensacion"
                                            value={ParametrosNomina.parafiscalescajacompensacion}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Parafiscales ICBF</Label>
                                        <Input
                                            type="number"
                                            name="parafiscalesicbf"
                                            value={ParametrosNomina.parafiscalesicbf}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Parafiscales SENA</Label>
                                        <Input
                                            type="number"
                                            name="parafiscalessena"
                                            value={ParametrosNomina.parafiscalessena}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Parafiscales Escuelas Industriales</Label>
                                        <Input
                                            type="number"
                                            name="parafiscalesescuelasindustriales"
                                            value={ParametrosNomina.parafiscalesescuelasindustriales}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Exonerado de Aportes Parafiscales</Label>
                                        <Input
                                            type="text"
                                            name="exoneradodeaportesparafiscales"
                                            value={ParametrosNomina.exoneradodeaportesparafiscales}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Consecutivo Nómina</Label>
                                        <Input
                                            type="text"
                                            name="consecutivonomina"
                                            value={ParametrosNomina.consecutivonomina}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal >
        </>
    );
};

export default ParametrosNomina;

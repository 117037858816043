import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from 'sweetalert2'
import { FormatMoney } from 'format-money-js'
import Autosuggest from 'react-autosuggest';
import { useContext, useState, useEffect, useRef } from "react";
import "./css/Venta.css"
import { Event, event } from "jquery";
import { jsPDF } from "jspdf";
import Icono from './images/blanco.png'
import { QRCode } from 'react-qrcode-logo';
import { renderToStaticMarkup } from "react-dom/server"
import ReactDOMServer from 'react-dom/server';
import { Autocomplete, TextField } from '@mui/material';
import { Try } from "@mui/icons-material";
const tabespacio = '\u00A0';
const modeloPais = {
    codigo: "",
    nombre: "",
}
const Tipopodf = (props) => {
    const [fm, setfm] = useState(new FormatMoney({ symbol: '' }));
    const [fm1, setfm1] = useState(new FormatMoney({ symbol: '', decimals: 1 }));
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));
    const [ivadiscriminado, setivadiscriminado] = useState([])

    useEffect(() => {


    }, [])

    const calculartotalesmovimientoivadiscriminado = (_enviodatos2) => {
        if (props.iva > 0) {
            try {

                var totalesiva = []
                _enviodatos2.map((dato, index) => {
                    var bruto = 0
                    var descuento = 0
                    var subtotal = 0
                    var cantidad = Number(dato.cantidad)
                    var precio = Number(dato.precio)
                    var pordescuento = Number(dato.pordescuento)
                    var iva = 0
                    bruto = (cantidad * precio)
                    descuento = bruto * (pordescuento / 100)
                    subtotal = bruto - descuento
                    var poriva = Number(dato.poriva)

                    iva = subtotal * (poriva / 100)
                    if (poriva > 0) {
                        var fbuss = totalesiva.filter(p => p.porcentaje == poriva)
                        if (fbuss.length == 0) {
                            var ivadi = {
                                porcentaje: poriva,
                                valor: iva
                            }
                            totalesiva.push(ivadi)

                        } else {
                            var posicion = totalesiva.findIndex(item => item.porcentaje === poriva)
                            if (posicion !== -1) {
                                totalesiva[posicion].valor += iva
                            } else {
                                var ivadi = {
                                    porcentaje: poriva,
                                    valor: iva
                                }
                                totalesiva.push(ivadi)
                            }
                        }

                    }
                })

                setivadiscriminado(totalesiva)
            } catch (error) {

            }
        }
    }

    function calcularDigitoVerificacion(myNit) {
        try {
            var vpri,
                x,
                y,
                z;

            // Se limpia el Nit
            myNit = myNit.replace(/\s/g, ""); // Espacios
            myNit = myNit.replace(/,/g, ""); // Comas
            myNit = myNit.replace(/\./g, ""); // Puntos
            myNit = myNit.replace(/-/g, ""); // Guiones

            // Se valida el nit
            if (isNaN(myNit)) {
                console.log("El nit/cédula '" + myNit + "' no es válido(a).");
                return "";
            };

            // Procedimiento
            vpri = new Array(16);
            z = myNit.length;

            vpri[1] = 3;
            vpri[2] = 7;
            vpri[3] = 13;
            vpri[4] = 17;
            vpri[5] = 19;
            vpri[6] = 23;
            vpri[7] = 29;
            vpri[8] = 37;
            vpri[9] = 41;
            vpri[10] = 43;
            vpri[11] = 47;
            vpri[12] = 53;
            vpri[13] = 59;
            vpri[14] = 67;
            vpri[15] = 71;

            x = 0;
            y = 0;
            for (var i = 0; i < z; i++) {
                y = (myNit.substr(i, 1));
                // console.log ( y + "x" + vpri[z-i] + ":" ) ;

                x += (y * vpri[z - i]);
                // console.log ( x ) ;
            }

            y = x % 11;
            // console.log ( y ) ;

            return (y > 1) ? 11 - y : y;
        } catch (error) {
            return ""
        }
    }

    const totalunidades = () => {
        var tot = 0
        for (let i = 0; i < props.Movimiento.famovimiento.length; i++) {
            const element = props.Movimiento.famovimiento[i];
            tot = tot + element.cantidad
        }
        return tot
    }
    return (
        <Modal size="lg" isOpen={props.previsualizar} onOpened={(e) => {
            if (props.tipodocumento.busplu == "si") {
                props.previsualizarpdf()

                if (!props.plantillapdf.estoybuscando) {
                    if (props.tipodocumento.facturapap != "electronica") {
                        props.setprevisualizar(false)

                    } else {
                        if (props.tipodocumento.docauto == "si") {
                            props.enviocorreofacturaelectronica()

                        }
                        props.setprevisualizar(false)
                    }

                }
            } else {
                if (props.tipodocumento.docauto == "si" && props.tipodocumento.facturapap == "electronica") {
                    props.enviocorreofacturaelectronica()

                }
            }
            calculartotalesmovimientoivadiscriminado(props.Movimiento.famovimiento)
        }}>
            <ModalHeader>
                Opciones
            </ModalHeader>
            <ModalBody id={"pruebapdfventaelectronica"} >
                <Row >
                    <Col sm={3}>
                        <Row>
                            <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >
                                {props.plantillapdf.mostrarlogo && <img src={"imagen/" + (props.sucursal.nombrecomercial ? (props.sucursal.nombrecomercial.toLowerCase().includes(".png") || props.sucursal.nombrecomercial.toLowerCase().includes(".jpg") ? props.sucursal.nombrecomercial : JSON.parse(props.user).empresa.config.ruta_logo) : JSON.parse(props.user).empresa.config.ruta_logo)} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                            </Col>
                            <Col sm={12} style={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraencabezado, color: props.plantillapdf.colorletra }}>

                                {props.plantillapdf.nombrereal && JSON.parse(props.user).empresa.empresa.nombre} {props.plantillapdf.nombrereal && <br />}
                                {!props.plantillapdf.nombrereal && (props.sucursal && props.sucursal.nit != "" ? props.sucursal.nombre : JSON.parse(props.user).empresa.config.encabezado_pdf)} {!props.plantillapdf.nombrereal && <br />}
                                {props.plantillapdf.nit && ("Nit: " + (props.sucursal && props.sucursal.nit != "" ? props.sucursal.nit : JSON.parse(props.user).empresa.empresa.nit + " - " + calcularDigitoVerificacion(JSON.parse(props.user).empresa.empresa.nit)))} {props.plantillapdf.nit && <br />}
                                {props.plantillapdf.resolucion && (props.tipodocumento.num_res != "" && "Resolución DIAN Facturación Electrónica No. " + props.tipodocumento.num_res)}{props.plantillapdf.resolucion && (props.tipodocumento.num_res != "" && <br />)}
                                {props.plantillapdf.resolucion && (props.tipodocumento.num_res != "" && " de " + props.tipodocumento.inicresolu + ".Rango autorizado del " + props.tipodocumento.prefijo + props.tipodocumento.rango_res_i + " al " + props.tipodocumento.prefijo + props.tipodocumento.rango_res_f)}{props.plantillapdf.resolucion && (props.tipodocumento.num_res != "" && <br />)}
                                {props.plantillapdf.resolucion && (props.tipodocumento.num_res != "" && ".Valido hasta " + props.tipodocumento.vencresolu)}{props.plantillapdf.resolucion && (props.tipodocumento.num_res != "" && <br />)}
                                {props.plantillapdf.numero && (props.tipodocumento.consecfac + " - " + props.tipodocumento.prefijo + props.Movimiento.ndocumento)} {props.plantillapdf.numero && <br />}
                                {props.plantillapdf.horafecha && ("Fecha: " + props.Movimiento.horafecha)}{props.plantillapdf.horafecha && (<br />)}
                                {props.plantillapdf.fecha && ("Fecha: " + props.Movimiento.fecha)}{props.plantillapdf.fecha && (<br />)}
                                {props.plantillapdf.telefono && ("Telefonos: " + (props.sucursal && props.sucursal.nit != "" ? props.sucursal.telefono : JSON.parse(props.user).empresa.empresa.telefono) + " - " + (props.sucursal && props.sucursal.nit != "" ? props.sucursal.celular : JSON.parse(props.user).empresa.empresa.celular))}{props.plantillapdf.telefono && (<br />)}
                                {props.plantillapdf.divisionencabezado1 && "-------------------------------------"}{props.plantillapdf.divisionencabezado1 && (<br />)}
                                {props.plantillapdf.nombrecliente && "Cliente: " + props.Movimiento.nombrecli}{props.plantillapdf.nombrecliente && (<br />)}
                                {props.plantillapdf.direccioncliente && "Direccion: " + props.tercero.direccion}{props.plantillapdf.direccioncliente && (<br />)}
                                {props.plantillapdf.direccioncliente && "Telefono: " + props.tercero.tel1}{props.plantillapdf.direccioncliente && (<br />)}
                                {props.plantillapdf.nitcliente && "Nit: " + props.Movimiento.cliente}{props.plantillapdf.nitcliente && (<br />)}
                                {props.plantillapdf.divisionencabezado2 && "-------------------------------------"}{props.plantillapdf.divisionencabezado2 && (<br />)}
                                {props.plantillapdf.usuariohizofactura && "Cajero: " + props.Movimiento.nomusuario}{props.plantillapdf.usuariohizofactura && (<br />)}
                                {props.plantillapdf.usuarioactual && "Cajero: " + props.dataUser.usuario.nombre}{props.plantillapdf.usuarioactual && (<br />)}
                                {props.plantillapdf.codigovendedor && "Cod. Vendedor: " + props.Movimiento.vendedor}{props.plantillapdf.codigovendedor && (<br />)}
                                {props.plantillapdf.nombrevendedor && "Vendedor: " + props.Movimiento.nombreven}{props.plantillapdf.nombrevendedor && (<br />)}
                            </Col>

                        </Row>
                        <Row >
                            <Col sm={12} style={{ marginBottom: props.plantillapdf.margenentreencabezadoeitems }}>
                                <Table size="sm">
                                    <thead>
                                        <tr style={{ fontSize: props.plantillapdf.tamanoletraencabezadogrilla, color: props.plantillapdf.colorletra, lineHeight: props.plantillapdf.espaciadoentreitems }}>
                                            {props.plantillapdf.cantidad && <th style={{ width: '10%', color: props.plantillapdf.colorletra }}>Cant</th>}
                                            {props.plantillapdf.codigoarticulo && <th style={{ width: '20%', color: props.plantillapdf.colorletra }}>Cod</th>}
                                            {props.plantillapdf.descripcionarticulo && <th style={{ width: '20%', color: props.plantillapdf.colorletra }}>Descr</th>}
                                            {props.plantillapdf.ivaarticulo && <th style={{ width: '10%', color: props.plantillapdf.colorletra }}>Iva</th>}
                                            {props.plantillapdf.porimptoconsumoarticulo != 0 && <td style={{ textAlign: 'left', color: props.plantillapdf.colorletra }}>Imp</td>}
                                            {props.plantillapdf.vlrimptoconsumoarticulo != 0 && <td style={{ textAlign: 'left', color: props.plantillapdf.colorletra }}>Imp</td>}
                                            {props.plantillapdf.valorunitarioarticulo && <th style={{ width: '10%', color: props.plantillapdf.colorletra }}>Vr.Unit</th>}
                                            {props.plantillapdf.valortotalarticulo && <th style={{ width: '10%', color: props.plantillapdf.colorletra }}>Vr.Total</th>}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (props.Movimiento.famovimiento.length < 1) ? (
                                                <tr>
                                                    <td colSpan="15">Sin Datos</td>
                                                </tr>
                                            ) :
                                                (
                                                    props.Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => {

                                                        return <tr style={{ fontSize: props.plantillapdf.tamanoletraitemsgrilla, color: props.plantillapdf.colorletra, lineHeight: props.plantillapdf.espaciadoentreitems }} key={item.item}>
                                                            {props.plantillapdf.cantidad && <td style={{ textAlign: 'left', color: props.plantillapdf.colorletra }} >{item.cantidad}</td>}
                                                            {props.plantillapdf.codigoarticulo && <td style={{ textAlign: 'left', color: props.plantillapdf.colorletra }} >{item.referencia}</td>}
                                                            {props.plantillapdf.descripcionarticulo && <td style={{ textAlign: 'left', color: props.plantillapdf.colorletra }} >{item.descrip}</td>}
                                                            {props.plantillapdf.ivaarticulo && <td style={{ textAlign: 'left', color: props.plantillapdf.colorletra }}>{fm.from(item.poriva)}</td>}
                                                            {props.plantillapdf.porimptoconsumoarticulo != 0 && <td style={{ textAlign: 'left', color: props.plantillapdf.colorletra }}>{fm.from(item.porimptoconsumo)}</td>}
                                                            {props.plantillapdf.vlrimptoconsumoarticulo != 0 && <td style={{ textAlign: 'left', color: props.plantillapdf.colorletra }}>{fm.from(item.vlrimptoconsumo)}</td>}
                                                            {props.plantillapdf.valorunitarioarticulo && <td style={{ textAlign: 'left', color: props.plantillapdf.colorletra }}>{fm.from(item.precio)}</td>}
                                                            {props.plantillapdf.valortotalarticulo && <td style={{ textAlign: 'left', color: props.plantillapdf.colorletra }}>{fm.from(item.subtotal)}</td>}



                                                        </tr>

                                                    })
                                                )


                                        }

                                    </tbody>
                                </Table>

                            </Col>
                            {/* props.plantillapdf.mostrarunidadestotalesitems */}
                            {true && <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: props.plantillapdf.colorletra }}>
                                {"Items:" + props.Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                {"Unidades:" + totalunidades()}
                                {"Forma de Pago:" + tabespacio + (props.tipodocumento.afecar != "" ? "Credito" : (props.tipodocumento.afecaj != "" && props.tipodocumento.afecaj != "nada") ? "Contado" : "") + tabespacio + tabespacio + tabespacio + tabespacio}

                            </Col>

                            }

                        </Row>

                        <Row>
                            <Col sm={12} style={{ fontSize: props.plantillapdf.tamanoletratotales, color: props.plantillapdf.colorletra }}>
                                <Table size="sm">
                                    <thead>

                                    </thead>
                                    <tbody >
                                        <tr style={{ lineHeight: props.plantillapdf.espaciadoentretotales }} >
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra }}>{"Subtotal:"}</th>
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra }}>{tabespacio + fm3.from(props.bruto)}</th>
                                        </tr>
                                        {props.plantillapdf.totaldescuento &&
                                            <tr style={{ lineHeight: props.plantillapdf.espaciadoentretotales }}>
                                                <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra }}>{"Descuento:"}</th>
                                                <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra }}>{tabespacio + fm3.from(props.descuento)}</th>
                                            </tr>
                                        }
                                        {props.plantillapdf.totaldescuento &&
                                            <tr style={{ lineHeight: props.plantillapdf.espaciadoentretotales }}>
                                                <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra }}>{"Base:"}</th>
                                                <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra }}>{tabespacio + fm3.from(props.subTotal)}</th>
                                            </tr>
                                        }
                                        {props.plantillapdf.totaliva &&
                                            <tr style={{ lineHeight: props.plantillapdf.espaciadoentretotales }}>
                                                <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra }}>{"Iva:"}</th>
                                                <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra }}>{tabespacio + fm3.from(props.iva)}</th>
                                            </tr>
                                        }
                                        {
                                            props.plantillapdf.ivadiscriminado && ivadiscriminado.map((dato) => {
                                                return <tr>
                                                    <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra, fontWeight: 'normal' }}>{"Iva " + dato.porcentaje + " : "}</th>
                                                    <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra, fontWeight: 'normal' }}>{tabespacio + fm2.from(dato.valor)}</th>
                                                </tr>
                                            })
                                        }
                                        {props.plantillapdf.totalconsumo && (props.tipodocumento.imptosn != "no" && props.consumo != 0) && <tr>
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra, fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra, fontWeight: 'normal' }}>{tabespacio + fm2.from(props.consumo)}</th>
                                        </tr>

                                        }
                                        {props.plantillapdf.totalretefuente && (props.tipodocumento.rete != "no" && props.retefuente != 0) && <tr>
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra, fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra, fontWeight: 'normal' }}>{tabespacio + fm2.from(props.retefuente)}</th>
                                        </tr>

                                        }
                                        {props.plantillapdf.totalreteiva && (props.tipodocumento.reteiva != "no" && props.reteiva != 0) && <tr>
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra, fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra, fontWeight: 'normal' }}>{tabespacio + fm2.from(props.reteiva)}</th>
                                        </tr>

                                        }

                                        {props.plantillapdf.totalreteica && (props.tipodocumento.ica != "no" && props.reteica != 0) && <tr>
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra, fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra, fontWeight: 'normal' }}>{tabespacio + fm2.from(props.reteica)}</th>
                                        </tr>

                                        }
                                        <tr style={{ lineHeight: props.plantillapdf.espaciadoentretotales }}>
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra }}>{"Neto:"}</th>
                                            <th style={{ textAlign: 'right', color: props.plantillapdf.colorletra }}>{tabespacio + fm3.from(props.total)}</th>
                                        </tr>
                                    </tbody>
                                </Table>


                            </Col>

                        </Row>
                        {props.plantillapdf.mostrarformasdepago && <Row>
                            <Col sm={12} sty le={{ textAlign: 'center', fontSize: props.plantillapdf.tamanoletraformapagotitulo }}>
                                <Row>
                                    <Col sm={3}>
                                        -----
                                    </Col>
                                    <Col sm={6} style={{ fontSize: props.plantillapdf.tamanoletraformapagotitulo, color: props.plantillapdf.colorletra }}>
                                        Medios de Pago
                                    </Col>
                                    <Col sm={3}>
                                        -----
                                    </Col></Row>
                                {props.valorescaja.map((dato) => {
                                    return <>
                                        <Row style={{ color: props.plantillapdf.colorletra, fontSize: props.plantillapdf.tamanoletraformapago }}>
                                            <Col sm={4} style={{ textAlign: 'left' }}>
                                                {dato.formapago.toUpperCase()}
                                            </Col>
                                            <Col sm={4} style={{ fontSize: props.plantillapdf.tamanoletraformapago }}>

                                            </Col>
                                            <Col sm={4} style={{ textAlign: 'left' }}>
                                                {fm.from(dato.valor)}
                                            </Col></Row>
                                    </>
                                })}

                            </Col>

                        </Row>}
                        {props.plantillapdf.firmar && <>
                            <Row style={{ marginTop: '40px' }}>
                                <Col sm={2} >

                                </Col>

                                <Col sm={7} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                </Col>
                                <Col sm={2} >

                                </Col>


                            </Row >
                            <Row >
                                <Col sm={3} >

                                </Col>

                                <Col sm={7} style={{ color: props.plantillapdf.colorletra }}>
                                    FIRMA
                                </Col>
                                <Col sm={1} >

                                </Col>


                            </Row >
                            <Row>
                                <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'white' }}>



                                </Col>

                            </Row></>}

                        {props.plantillapdf.mostrarcufe &&
                            <Col sm={12} style={{ textAlign: 'center', fontSize: '7px', color: 'black' }}>

                                {"Cufe:"}<br />
                                {props.Movimiento.cufe && props.Movimiento.cufe.substring(0, props.Movimiento.cufe.length / 2)}<br />
                                {props.Movimiento.cufe && props.Movimiento.cufe.substring(props.Movimiento.cufe.length / 2, props.Movimiento.cufe.length)}<br />

                            </Col>


                        }
                        <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: props.plantillapdf.colorletra, whiteSpace: 'pre-line' }}>
                            {"Observaciones:"}<br />
                            {(props.tipodocumento.camdescpp && props.tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio) + props.Movimiento.observacion}

                        </Col>
                        {props.plantillapdf.mostrarqr && <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>

                            <QRCode size={200} qrStyle="squares" ecLevel="H" quietZone={10} value={props.Movimiento.qr ? props.Movimiento.qr : ""} />
                            {/* <QRCode size={200} qrStyle="squares"  ecLevel="H" quietZone={10} value={"https://catalogo-vpfe.dian.gov.co/User/SearchDocument"} /> */}

                        </Col>


                        }
                        <Row>
                            <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: props.plantillapdf.colorletra }}>

                                {"Gracias Por Su Compra"}<br />
                                {"Numeros del Software"}<br />
                                {"Generado por Solifactin S.A.S"}<br />
                                {"Tel:3187653564 - 3187653564 - 3218561024"}

                            </Col>

                        </Row>

                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Col sm={6}>
                    <FormGroup>
                        {props.certificado.map((data) => {
                            return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                        })}
                    </FormGroup>
                </Col>
                <Col sm={3}>
                    <FormGroup>
                        <Label>Adjuntar</Label>
                        <Input bsSize="sm" type={"file"} onChange={async (e) => {
                            var archivoxml = e.target.files[0]
                            var cerbase64 = await props.base64certificado(archivoxml)
                            var adjuntos = props.certificado.map((data) => {
                                return data
                            })
                            adjuntos.push({
                                nombre: archivoxml.name,
                                adjunto: cerbase64
                            })
                            props.setcertificado(adjuntos)

                        }} >


                        </Input>
                    </FormGroup>
                </Col>
                <Button size="sm" color="primary" onClick={() => {
                    props.setprevisualizarenviocorreo(true)
                    props.setcorreosenvio(props.tercero.email)
                }}>Enviar Correo</Button>
                <Button size="sm" color="primary" onClick={() => {

                    props.setprevisualizar(false)
                    props.previsualizarpdf()
                }}>PDF</Button>
                <Button size="sm" color="primary" onClick={() => {
                    props.setprevisualizar(false)
                    props.setcertificado([])
                }}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}
export default Tipopodf;

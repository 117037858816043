import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap";
import Swal from 'sweetalert2';
import Tabs from 'react-bootstrap/Tabs';
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab';
import Buscar from '../componentes/Buscar'; 

const modeloEmpleado = {
    codigo: "",
    cedula: "",
    nombre: "",
    tipodocumento: "",
    nombre1: "",
    nombre2: "",
    apellido1: "",
    apellido2: "",
    direccion: "",
    telefono1: "",
    telefono2: "",
    sexo: "",
    estadocivil: "",
    fechanacimiento: "",
    profesion: "",
    correo: "",
    municipio: "",
    departamento: "",
    pais: "",
    nommunicipio: "",
    nomdepartamento: "",
    nompais: "",
    turno: "",
    seccion: "",
    centrodetrabajo: "",
    centrodecosto: "",
    division: "",
    oficio: "",
    fechaingreso: "",
    fechaultimoaumento: "",
    retiro: "",
    fecharetiro: "",
    tipodecontrato: "",
    tipocotizante: "",
    subtipocotizante: "",
    tipodesueldo: "",
    tipodeliquidacion: "",
    talla1: "",
    talla2: "",
    talla3: "",
    formadepago: "",
    mediodepago: "",
    sueldo: 0,
    auxiliodetransporte: "",
    periauxiliodetransporte: "",
    valorhoraactual: "",
    valorhoraanterior: "",
    valorhoraespecial: "",
    liqcesanultsueldo: "",
    conceptoespecial: "",
    valorconceptoespecial: "",
    banco: "",
    cuenta: "",
    tipocuenta: "",
    lugardepago: "",
    administradoracesantias: "",
    administradorasalud: "",
    deduccionsalud: "",
    valorupc: 0,
    deduccionvalorupc: "",
    fechaperiodocesantias: "",
    fechaperiodoprimas: "",
    administradorapension: "",
    deduccionpension: "",
    aportevoluntario: "",
    deduccionaportevoluntario: "",
    fondosolidaridad: "",
    tarifaespecial: "",
    urlfoto: "",
    opcion: "",
    opcion2: "",
    opcion3: "",
    opcion4: "",
    opcion5: ""
};

const Empleados = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Empleado, setEmpleado] = useState(modeloEmpleado);
    const [Empleados, setEmpleados] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalEmpleado, setVerModalEmpleado] = useState(false);
    const [verModaltipodocumentos, setverModaltipodocumentos] = useState(false);

    const [key, setKey] = useState('Informacion Personal');
    const [editar, seteditar] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [titulobuscar, settitulobuscar] = useState("");
    const [namebuscado, setnamebuscado] = useState("");
    const [encabezadobuscar, setencabezadobuscar] = useState([]);
    const [datosbuscados, setdatosbuscados] = useState([]);
    const [Oficios, setOficios] = useState([]);
    const [Division, setDivision] = useState([]);
    const [CentrodeCosto, setCentrodeCosto] = useState([]);
    const [CentrosdeTrabajo, setCentrosdeTrabajo] = useState([]);
    const [Secciones, setSecciones] = useState([]);
    const [Administradora, setAdministradora] = useState([]);
    const handleChange = (e) => {
        let value = e.target.value;
        var _Empleado = { ...Empleado };
        _Empleado[e.target.name] = value;
        setEmpleado(_Empleado);
    };
    const obtenerAdministradoras = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);

            let response = await fetch(props.servidor + '/AdministradorasApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setAdministradora(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const obtenerOficio = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);

            let response = await fetch(props.servidor + '/OficiosApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setOficios(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const obtenerDivision = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/DivisionesApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setDivision(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const obtenerCentrosdeCosto = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/Ctcentrocostoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setCentrodeCosto(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const obtenerCentrosdetrabajo = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/CentrosdeTrabajoApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setCentrosdeTrabajo(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const obtenerSecciones = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/SeccionesApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setSecciones(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const obtenerTurnos = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/EmpleadosApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setEmpleados(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const obtenerEmpleados = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/EmpleadosApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setEmpleados(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        let dt = JSON.parse(user);
        setDataUser(dt);
        obtenerEmpleados('');
        obtenerOficio('')
        obtenerDivision('')
        obtenerCentrosdetrabajo('')
        obtenerSecciones('')
        obtenerEmpleados('')
        obtenerCentrosdeCosto('')
        obtenerAdministradoras('')
    }, []);

    const columns = [
        {
            name: 'Código',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalEmpleado(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>
                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const abrirEditarModalEmpleado = (data) => {
        var enviodata = { ...data };
        var _Empleado = {
            codigo: enviodata.codigo,
            cedula: enviodata.cedula,
            nombre: enviodata.nombre,
            tipodocumento: enviodata.tipodocumento,
            nombre1: enviodata.nombre1,
            nombre2: enviodata.nombre2,
            apellido1: enviodata.apellido1,
            apellido2: enviodata.apellido2,
            direccion: enviodata.direccion,
            telefono1: enviodata.telefono1,
            telefono2: enviodata.telefono2,
            sexo: enviodata.sexo,
            estadocivil: enviodata.estadocivil,
            fechanacimiento: enviodata.fechanacimiento,
            profesion: enviodata.profesion,
            correo: enviodata.correo,
            municipio: enviodata.municipio,
            departamento: enviodata.departamento,
            pais: enviodata.pais,
            nommunicipio: enviodata.nommunicipio,
            nomdepartamento: enviodata.nomdepartamento,
            nompais: enviodata.nompais,
            turno: enviodata.turno,
            seccion: enviodata.seccion,
            centrodetrabajo: enviodata.centrodetrabajo,
            centrodecosto: enviodata.centrodecosto,
            division: enviodata.division,
            oficio: enviodata.oficio,
            fechaingreso: enviodata.fechaingreso,
            fechaultimoaumento: enviodata.fechaultimoaumento,
            retiro: enviodata.retiro,
            fecharetiro: enviodata.fecharetiro,
            tipodecontrato: enviodata.tipodecontrato,
            tipocotizante: enviodata.tipocotizante,
            subtipocotizante: enviodata.subtipocotizante,
            tipodesueldo: enviodata.tipodesueldo,
            tipodeliquidacion: enviodata.tipodeliquidacion,
            talla1: enviodata.talla1,
            talla2: enviodata.talla2,
            talla3: enviodata.talla3,
            formadepago: enviodata.formadepago,
            mediodepago: enviodata.mediodepago,
            sueldo: enviodata.sueldo,
            auxiliodetransporte: enviodata.auxiliodetransporte,
            periauxiliodetransporte: enviodata.periauxiliodetransporte,
            valorhoraactual: enviodata.valorhoraactual,
            valorhoraanterior: enviodata.valorhoraanterior,
            valorhoraespecial: enviodata.valorhoraespecial,
            liqcesanultsueldo: enviodata.liqcesanultsueldo,
            conceptoespecial: enviodata.conceptoespecial,
            valorconceptoespecial: enviodata.valorconceptoespecial,
            banco: enviodata.banco,
            cuenta: enviodata.cuenta,
            tipocuenta: enviodata.tipocuenta,
            lugardepago: enviodata.lugardepago,
            administradoracesantias: enviodata.administradoracesantias,
            administradorasalud: enviodata.administradorasalud,
            deduccionsalud: enviodata.deduccionsalud,
            valorupc: enviodata.valorupc,
            deduccionvalorupc: enviodata.deduccionvalorupc,
            fechaperiodocesantias: enviodata.fechaperiodocesantias,
            fechaperiodoprimas: enviodata.fechaperiodoprimas,
            administradorapension: enviodata.administradorapension,
            deduccionpension: enviodata.deduccionpension,
            aportevoluntario: enviodata.aportevoluntario,
            deduccionaportevoluntario: enviodata.deduccionaportevoluntario,
            fondosolidaridad: enviodata.fondosolidaridad,
            tarifaespecial: enviodata.tarifaespecial,
            urlfoto: enviodata.urlfoto,
            opcion: enviodata.opcion,
            opcion2: enviodata.opcion2,
            opcion3: enviodata.opcion3,
            opcion4: enviodata.opcion4,
            opcion5: enviodata.opcion5
        };
        setEmpleado(_Empleado);
        setVerModalEmpleado(!verModalEmpleado);
        seteditar(true);
    };

    const cerrarModalEmpleado = () => {
        setEmpleado(modeloEmpleado);
        setVerModalEmpleado(!verModalEmpleado);
        seteditar(false);
        setKey('Informacion Personal');
    };
    const cerrartodo = () => {
        settitulobuscar("")
        setencabezadobuscar([])
        setverModaltipodocumentos(false)
        setdatosbuscados([])
        setnamebuscado("")
    };

    const guardarCambios = async () => {
        let response;
        var _Empleado={...Empleado}
        _Empleado.sueldo=Number(_Empleado.sueldo)
        if (!editar) {
            response = await fetch("" + props.servidor + "/EmpleadosApi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(_Empleado)
            });
        } else {
            response = await fetch("" + props.servidor + "/EmpleadosApi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(_Empleado)
            });
        }

        if (response.ok) {
            await obtenerEmpleados('');
            setEmpleado(modeloEmpleado);
            setVerModalEmpleado(!verModalEmpleado);
            setKey('Informacion Personal');
            Swal.fire(
                'Guardado Exitosamente!',
                'El empleado fue guardado exitosamente',
                'success'
            );
        } else {
            var textoerrores = await response.text();
            var jsonerrores = JSON.parse(textoerrores);
            var errores = jsonerrores.errors;
            let claves = Object.keys(errores);
            var stringerrores = "";
            for (let i = 0; i < claves.length; i++) {
                let clave = claves[i];
                if (errores[clave].length != 0) {
                    stringerrores = stringerrores + errores[clave][0] + " \n ";
                }
            }
            var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido');
            if (mensaje.includes('not be converted to System.Decimal')) {
                mensaje = 'Hay campos numéricos rellenados con letras';
            }
            console.log(stringerrores);
            Swal.fire(
                'Error al Guardar!',
                mensaje,
                'error'
            );
        }
    };

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Empleados
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <input type="text" id="buscardataEmpleados" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerEmpleados(document.getElementById('buscardataEmpleados').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalEmpleado(!verModalEmpleado);
                                seteditar(false);
                            }}>Nuevo</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcrearempleado(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </div>
                    </div>

                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Empleados}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" style={{ maxWidth: '1600px', width: '95%' }} isOpen={verModalEmpleado}>
                <ModalHeader>
                    Empleado
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="pestañas"
                    >
                        <Tab eventKey="Informacion Personal" title="Informacion Personal">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Código</Label>
                                        <Input disabled={editar} type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Empleado.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Cédula</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="cedula" value={Empleado.cedula} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Nombre</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Empleado.nombre} />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tipo de Documento</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="tipodocumento" value={Empleado.tipodocumento} >
                                            <option value="31">Nit</option>
                                            <option value="13">Cedula</option>
                                            <option value="22">Cedula Extranjeria</option>
                                            <option value="41">Pasaporte</option>
                                            <option value="11">Registro Civil</option>
                                            <option value="12">Tarjeta de Identidad</option>
                                            <option value="42">Documento de Identifiacion Extranjero</option>
                                            <option value="10">Tarjeta de Extranjeria</option>
                                            <option value="91">NUIP</option>
                                            <option value="47">Permiso Especial de Permanencia (PEP)</option>
                                            <option value="13">Sin Identificación del Exterior o para uso Definido por la DIAN</option>
                                            <option value="50">Nit de otro País</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Primer Nombre</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre1" value={Empleado.nombre1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Segundo Nombre</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre2" value={Empleado.nombre2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Primer Apellido</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="apellido1" value={Empleado.apellido1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Segundo Apellido</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="apellido2" value={Empleado.apellido2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Dirección</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="direccion" value={Empleado.direccion} />
                                    </FormGroup>
                                </Col>


                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Teléfono 1</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="telefono1" value={Empleado.telefono1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Teléfono 2</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="telefono2" value={Empleado.telefono2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Sexo</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="sexo" value={Empleado.sexo} >
                                            <option value="">-</option>
                                            <option value="femenino">Femenino</option>
                                            <option value="masculino">Masculino</option>

                                        </Input>
                                    </FormGroup>
                                </Col>


                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Estado Civil</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="estadocivil" value={Empleado.estadocivil}>
                                            <option value="">-</option>
                                            <option value="soltero">Soltero(a)</option>
                                            <option value="casado">Casado(a)</option>
                                            <option value="otro">Otro</option>

                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha de Nacimiento</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fechanacimiento" value={Empleado.fechanacimiento} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Profesión</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="profesion" value={Empleado.profesion} />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Correo</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="correo" value={Empleado.correo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Municipio</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="municipio" value={Empleado.municipio} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Departamento</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="departamento" value={Empleado.departamento} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>País</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="pais" value={Empleado.pais} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Nombre Municipio</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nommunicipio" value={Empleado.nommunicipio} />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Nombre Departamento</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nomdepartamento" value={Empleado.nomdepartamento} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Nombre País</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nompais" value={Empleado.nompais} />
                                    </FormGroup>
                                </Col>

                            </Row>
                        </Tab>

                        <Tab eventKey="Informacion Laboral" title="Informacion Laboral">

                            <Row>
                                {/* <Col sm={3}>
                                    <FormGroup>
                                        <Label>Turno</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="turno" value={Empleado.turno} />
                                    </FormGroup>
                                </Col> */}
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Sección</Label>
                                        <Input onFocus={() => {
                                            settitulobuscar("Secciones")
                                            setencabezadobuscar(["codigo", "nombre", "", ""])
                                            setnamebuscado("seccion")
                                        }} type="text" bsSize="sm" onKeyDown={(e) => {
                                            if (e.key == "Enter") {

                                                setverModaltipodocumentos(true)
                                                setdatosbuscados(Secciones)
                                            }
                                        }} name="seccion" value={Empleado.seccion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Centro de Trabajo</Label>
                                        <Input type="text" bsSize="sm" onFocus={() => {
                                            settitulobuscar("Centro de Trabajo")
                                            setencabezadobuscar(["codigo", "nombre", "", ""])
                                            setnamebuscado("centrodetrabajo")
                                        }} onKeyDown={(e) => {
                                            if (e.key == "Enter") {

                                                setverModaltipodocumentos(true)
                                                setdatosbuscados(CentrosdeTrabajo)
                                            }
                                        }} name="centrodetrabajo" value={Empleado.centrodetrabajo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Centro de Costo</Label>
                                        <Input type="text" bsSize="sm" onFocus={() => {
                                            settitulobuscar("Centro de Costo")
                                            setencabezadobuscar(["codigo", "nombre", "", ""])
                                            setnamebuscado("centrodecosto")
                                        }} onKeyDown={(e) => {
                                            if (e.key == "Enter") {

                                                setverModaltipodocumentos(true)
                                                setdatosbuscados(CentrodeCosto)
                                            }
                                        }} onChange={handleChange} name="centrodecosto" value={Empleado.centrodecosto} />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>División</Label>
                                        <Input type="text" bsSize="sm" onFocus={() => {
                                            settitulobuscar("Division")
                                            setencabezadobuscar(["codigo", "nombre", "", ""])
                                            setnamebuscado("division")
                                        }} onKeyDown={(e) => {
                                            if (e.key == "Enter") {

                                                setverModaltipodocumentos(true)
                                                setdatosbuscados(Division)
                                            }
                                        }} onChange={handleChange} name="division" value={Empleado.division} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Oficio</Label>
                                        <Input type="text" bsSize="sm" onFocus={() => {
                                            settitulobuscar("Oficio")
                                            setencabezadobuscar(["codigo", "nombre", "", ""])
                                            setnamebuscado("oficio")
                                        }} onKeyDown={(e) => {
                                            if (e.key == "Enter") {

                                                setverModaltipodocumentos(true)
                                                setdatosbuscados(Oficios)
                                            }
                                        }} onChange={handleChange} name="oficio" value={Empleado.oficio} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha de Ingreso</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fechaingreso" value={Empleado.fechaingreso} />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha del Último Aumento</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fechaultimoaumento" value={Empleado.fechaultimoaumento} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Retiro</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="retiro" value={Empleado.retiro} >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>

                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha de Retiro</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fecharetiro" value={Empleado.fecharetiro} />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tipo de Contrato</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="tipodecontrato" value={Empleado.tipodecontrato}>
                                            <option value="">-</option>
                                            <option value="termino fijo">Termino Fijo</option>
                                            <option value="termino indefinido">Termino Indefinido</option>
                                            <option value="obra o labor">Obra o Labor</option>
                                            <option value="aprendizaje">Aprendizaje</option>
                                            <option value="practicas o pasantias">Practicas o Pasantias</option>
                                            <option value="medio tiempo">Medio Tiempo</option>

                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tipo de Cotizante</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="tipocotizante" value={Empleado.tipocotizante}  >
                                            <option value="1">1 - Dependiente</option>
                                            <option value="12">12 - Aprendiz en etapa lectiva</option>
                                            <option value="18">18 - Servidores públicos sin tope máximo en el IBC</option>
                                            <option value="19">19 - Aprendiz en etapa productiva</option>
                                            <option value="20">20 - Estudiante (Régimen especial Ley 789 de 2002)</option>
                                            <option value="22">22 - Profesor de establecimiento particular</option>
                                            <option value="23">23 - Estudiante Decreto 055 de 2015</option>
                                            <option value="40">40 - Beneficiario de UPC Adicional</option>
                                            <option value="47">47 - Dependiente de entidad beneficiaria de SGP</option>
                                            <option value="51">51 - Trabajador de tiempo parcial Afiliado al Régimen Subsidiado</option>
                                            <option value="52">52 - Beneficiario del Mecanismo de Protección al Cesante</option>
                                            <option value="58">58 - Estudiante de práctica laboral en el sector público</option>
                                            <option value="59">59 - Independiente con contrato de prestación de servicios superior a 1 mes</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Subtipo de Cotizante</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="subtipocotizante" value={Empleado.subtipocotizante} >
                                            <option value="0">0 - Ninguno</option>
                                            <option value="1">1 - Dependiente Pensionado Activo</option>
                                            <option value="3">3 - Cotizante no Obligado a Cotización a Pensiones por Edad</option>
                                            <option value="4">4 - Con Requisitos Cumplidos para Pensión, Indemnización Sustitutiva o Devolución de Saldos</option>
                                            <option value="5">5 - Cotizante con Devolución de Saldos</option>
                                            <option value="6">6 - Cotizante Perteneciente a Régimen Exceptuado</option>
                                            <option value="9">9 - Cotizante Pensionado con Mesada Superior a 25 SMMLV</option>
                                            <option value="11">11 - Conductor de Vehículo Taxi</option>
                                            <option value="12">12 - Conductor de Vehículo Taxi no obligado a cotizar a pensión</option>
                                        </Input>

                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tipo de Sueldo</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="tipodesueldo" value={Empleado.tipodesueldo} >
                                            <option value="fijo">Fijo</option>
                                            <option value="variable">Variable</option>
                                            <option value="integral">Integral</option>

                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tipo de Liquidación</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="tipodeliquidacion" value={Empleado.tipodeliquidacion} >
                                            {/* <option value="semanal">Fijo</option> */}
                                            {/* <option value="decadal">Fijo</option> */}
                                            {/* <option value="catorcenal">Fijo</option> */}
                                            <option value="quincenal">Quincenal</option>
                                            <option value="mensual">Mensual</option>

                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Talla 1</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="talla1" value={Empleado.talla1} />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Talla 2</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="talla2" value={Empleado.talla2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Talla 3</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="talla3" value={Empleado.talla3} />
                                    </FormGroup>
                                </Col>
                                {/* <Col sm={3}>
                                    <FormGroup>
                                        <Label>Forma de Pago</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="formadepago" value={Empleado.formadepago} >
                                            <option value="efectivo">Efectivo</option>
                                            <option value="transferencia">Quincenal</option>

                                        </Input>

                                    </FormGroup>
                                </Col> */}
                            </Row>
                        </Tab>

                        <Tab eventKey="Informacion Economica" title="Informacion Economica">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Medio de Pago</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="mediodepago" value={Empleado.mediodepago} >
                                            <option value="efectivo">Efectivo</option>
                                            <option value="transferencia">Transferencia</option>

                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Sueldo</Label>
                                        <Input bsSize="sm" onChange={handleChange} name="sueldo" value={Empleado.sueldo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Auxilio de Transporte</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="auxiliodetransporte" value={Empleado.auxiliodetransporte} >
                                            <option value="si">Si</option>
                                            <option value="no">No</option>

                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Período de Auxilio de Transporte</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="periauxiliodetransporte" value={Empleado.periauxiliodetransporte} >
                                            <option value="periodica">Periodica</option>
                                            <option value="mensual">Mensual</option>

                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Valor Hora Actual</Label>
                                        <Input bsSize="sm" onChange={handleChange} name="valorhoraactual" value={Empleado.valorhoraactual} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Valor Hora Anterior</Label>
                                        <Input bsSize="sm" onChange={handleChange} name="valorhoraanterior" value={Empleado.valorhoraanterior} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Valor Hora Especial</Label>
                                        <Input bsSize="sm" onChange={handleChange} name="valorhoraespecial" value={Empleado.valorhoraespecial} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Liquidación Cesantías Último Sueldo</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="liqcesanultsueldo" value={Empleado.liqcesanultsueldo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Concepto Especial</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="conceptoespecial" value={Empleado.conceptoespecial} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Valor Concepto Especial</Label>
                                        <Input bsSize="sm" onChange={handleChange} name="valorconceptoespecial" value={Empleado.valorconceptoespecial} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Banco</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="banco" value={Empleado.banco} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Cuenta</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="cuenta" value={Empleado.cuenta} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tipo de Cuenta</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="tipocuenta" value={Empleado.tipocuenta} >
                                            <option value="ahorro">Ahorro</option>
                                            <option value="corriente">Corriente</option>

                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Lugar de Pago</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="lugardepago" value={Empleado.lugardepago} />
                                    </FormGroup>
                                </Col>

                            </Row>
                        </Tab>

                        <Tab eventKey="Prestaciones Sociales Y Seguridad Social" title="Prestaciones Sociales Y Seguridad Social">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Administradora Salud</Label>
                                        <Input type="text" bsSize="sm" onFocus={() => {
                                            settitulobuscar("Administradora Salud")
                                            setencabezadobuscar(["codigo", "razsoc", "", ""])
                                            setnamebuscado("administradorasalud")
                                        }} onKeyDown={(e) => {
                                            if (e.key == "Enter") {

                                                setverModaltipodocumentos(true)
                                                setdatosbuscados(Administradora)
                                            }
                                        }} name="administradorasalud" value={Empleado.administradorasalud} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Deducción Salud</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="deduccionsalud" value={Empleado.deduccionsalud} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Valor UPC</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="valorupc" value={Empleado.valorupc} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Administradora Pensión</Label>
                                        <Input type="text" bsSize="sm" onFocus={() => {
                                            settitulobuscar("Administradora Cesantías")
                                            setencabezadobuscar(["codigo", "razsoc", "", ""])
                                            setnamebuscado("administradoracesantias")
                                        }} onKeyDown={(e) => {
                                            if (e.key == "Enter") {

                                                setverModaltipodocumentos(true)
                                                setdatosbuscados(Administradora)
                                            }
                                        }} name="administradorapension" value={Empleado.administradorapension} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Deducción Pensión</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="deduccionpension" value={Empleado.deduccionpension} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Aporte Voluntario</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="aportevoluntario" value={Empleado.aportevoluntario} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Deducción Valor UPC</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="deduccionvalorupc" value={Empleado.deduccionvalorupc} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Administradora Cesantías</Label>
                                        <Input type="text" bsSize="sm" onFocus={() => {
                                            settitulobuscar("Administradora Cesantías")
                                            setencabezadobuscar(["codigo", "razsoc", "", ""])
                                            setnamebuscado("administradoracesantias")
                                        }} onKeyDown={(e) => {
                                            if (e.key == "Enter") {

                                                setverModaltipodocumentos(true)
                                                setdatosbuscados(Administradora)
                                            }
                                        }} name="administradoracesantias" value={Empleado.administradoracesantias} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Periodo Cesantías</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="fechaperiodocesantias" value={Empleado.fechaperiodocesantias} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Periodo Primas</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="fechaperiodoprimas" value={Empleado.fechaperiodoprimas} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Deducción Aporte Voluntario</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="deduccionaportevoluntario" value={Empleado.deduccionaportevoluntario} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fondo de Solidaridad</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="fondosolidaridad" value={Empleado.fondosolidaridad} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tarifa Especial</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="tarifaespecial" value={Empleado.tarifaespecial} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>URL Foto</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="urlfoto" value={Empleado.urlfoto} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Opción 1</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion" value={Empleado.opcion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Opción 2</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion2" value={Empleado.opcion2} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Opción 3</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion3" value={Empleado.opcion3} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Opción 4</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion4" value={Empleado.opcion4} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Opción 5</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion5" value={Empleado.opcion5} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>

                    </Tabs>
                    <Buscar tituloencabezado={titulobuscar} filtrar={(coincidencia) => {
                        // filtrartipodocumentos(coincidencia)
                    }}
                        busqueda={datosbuscados} encabezado={encabezadobuscar}
                        codigo={encabezadobuscar[0]} nombre={encabezadobuscar[1]} dato={encabezadobuscar[2]} dato2={encabezadobuscar[3]}
                        verModal={verModaltipodocumentos} cerrarModal={() => {
                            cerrartodo()

                        }} seleccionar={(seleccionado) => {
                            var _Empleado = { ...Empleado }
                            _Empleado[namebuscado] = seleccionado[encabezadobuscar[0]]
                            cerrartodo()
                            setEmpleado(_Empleado)
                        }} />

                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalEmpleado}>Cerrar</Button>
                </ModalFooter>
            </Modal >
        </>
    );
}

export default Empleados;
